import axios, { AxiosResponse, AxiosError } from "axios";
import { useMutation } from "react-query";
import { AIRequest } from "../types/requestTypes";
import { APIURL } from "./apiUtils/apiUrl";

export const useCreateTranscription = () => {
  const url = APIURL.ai;

  return useMutation<AxiosResponse<void>, AxiosError, AIRequest>((data) =>
    axios.post(url, data)
  );
};
