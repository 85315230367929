import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tag, TagProps } from "antd";
import { Link } from "react-router-dom";
import { style } from "typestyle";

interface ExistTagProps extends TagProps {
  contentExists: boolean;
  fileType: "Video" | "Audio" | "PDF" | "PowerPoint";
  mediaId: string;
}

const tagPointer = style({
  cursor: "pointer",
  margin: "0 8px 4px 0",
});

const tagNonePointer = style({
  userSelect: "none",
  margin: "0 8px 4px 0",
});

export const ExistTag: React.FC<ExistTagProps> = ({
  contentExists,
  fileType,
  mediaId,
  ...tagProps
}) => {
  if (!contentExists) {
    return (
      <Tag
        {...tagProps}
        icon={<CloseOutlined />}
        color={"error"}
        className={tagNonePointer}
      >
        {fileType}
      </Tag>
    );
  }

  return (
    <Tag
      {...tagProps}
      icon={<CheckOutlined />}
      color={"success"}
      className={tagPointer}
    >
      <Link to={mediaId} style={{ color: "inherit" }}>
        {fileType}
      </Link>
    </Tag>
  );
};
