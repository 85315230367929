import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactPlayer from "react-player";
import { BasicTitle } from "..";

interface MediaPlayerProps {
  type: "audio" | "video";
  mediaUrl: string;
  title?: string;
}

export const MediaPlayer: React.FC<MediaPlayerProps> = ({
  type,
  mediaUrl,
  title,
}) => {
  const audio = type === "audio";
  return (
    <>
      {title && <BasicTitle level={5}>{title}</BasicTitle>}
      {audio ? (
        <AudioPlayer
          src={mediaUrl}
          preload="metadata"
          customControlsSection={[
            <div></div>,
            RHAP_UI.MAIN_CONTROLS,
            <div></div>,
          ]}
        />
      ) : (
        <ReactPlayer
          width="100%"
          height="auto"
          controls
          url={mediaUrl}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
                preload: "none",
              },
            },
          }}
        />
      )}
    </>
  );
};
