import { Configuration } from "../../utils/configuration";

const API_URL = Configuration.API_URL;
const FRONTEND_PUBLIC_URL = Configuration.FRONTEND_PUBLIC_URL;
const DIRECT_API_URL = API_URL || "http://192.168.178.30:7000";

const medias = `${API_URL}/medias`;
const filtertags = `${API_URL}/filtertags`;
const mediaId = (mediaId: string) => `${medias}/${mediaId}`;

const streamKey = `${API_URL}/streamkey`;
const stream = (fileName: string) => `${API_URL}/streams/${fileName}`;
const download = (fileName: string) =>
  `${DIRECT_API_URL}/downloads/${fileName}`;

const observerStatus = `${API_URL}/observer`;
const converter = `${API_URL}/converter`;
const converterConvert = `${converter}/convert`;
const converterMerge = `${converter}/merge`;
const converterId = (id: string) => `${converter}/${id}`;
const converterStream = (fileName: string) =>
  `${converter}/streams/${fileName}`;
const log = `${API_URL}/logs`;
const database = `${API_URL}/database`;
const untrackedFile = `${API_URL}/untrackedfiles`;
const mp3 = `${API_URL}/mp3s`;
const ai = `${API_URL}/ai`;
const generalInfos = `${API_URL}/infos`;

export const APIURL = {
  log,
  observerStatus,
  download,
  stream,
  mediaId,
  filtertags,
  medias,
  database,
  untrackedFile,
  mp3,
  ai,
  frontendUrl: FRONTEND_PUBLIC_URL,
  generalInfos,
  streamKey,
  converter,
  converterStream,
  converterId,
  converterConvert,
  converterMerge,
};
