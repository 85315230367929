import { Layout } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Content } from "antd/lib/layout/layout";
import { Outlet } from "react-router";
import { style } from "typestyle";
import { MobileMenu, NormalMenu } from ".";
import { CenterCol, CenterRow } from "../../components";

const contentStyle = (smallScreen?: boolean) =>
  style({
    marginTop: smallScreen ? 15 : 75,
    marginBottom: 100,
  });

export const SiteLayout: React.FC = () => {
  const { xs, sm, md } = useBreakpoint();
  const smallScreen = xs || (sm && !md);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {smallScreen ? <MobileMenu /> : <NormalMenu />}
      <Content className={contentStyle(smallScreen)}>
        <CenterRow>
          <CenterCol xs={22} md={23}>
            <Outlet />
          </CenterCol>
        </CenterRow>
      </Content>
    </Layout>
  );
};
