import { MenuOutlined } from "@ant-design/icons";
import { Affix, Button, Drawer, Row } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../../providers/CustomKeycloakProvider";
import { PageRoutes } from "../../../utils/pageRoutes";
import { MenuEntries } from "./menuEntries";
import { MenuItem } from "./MenuItem";

export const MobileMenu: React.FC = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { login, logout, activeUser, userHasRole } = useAuth();

  const routerNavigate = useNavigate();

  const openDrawer = () => setShowDrawer(true);
  const closeDrawer = () => setShowDrawer(false);

  const executeAndCloseDrawer = (executable: () => void) => {
    executable();
    closeDrawer();
  };

  const navigate = (to: string) => {
    routerNavigate(to);
    closeDrawer();
  };

  return (
    <>
      <Affix offsetTop={10}>
        <Button onClick={openDrawer}>
          <MenuOutlined />
        </Button>
      </Affix>
      <Drawer
        title="Menu"
        placement={"top"}
        open={showDrawer}
        key={"top"}
        onClose={closeDrawer}
        extra={
          <Button
            onClick={() => executeAndCloseDrawer(activeUser ? logout : login)}
          >
            {activeUser ? "Logout" : "Login"}
          </Button>
        }
      >
        <Row gutter={[0, 10]}>
          <MenuItem
            mobile={{
              pageRoute: PageRoutes.home,
              navigateAndCloseDrawer: navigate,
            }}
          />
          <>
            {MenuEntries.filter((entry) => userHasRole(entry.accessRole)).map(
              (pageRoute, i) => (
                <MenuItem
                  key={i}
                  mobile={{
                    pageRoute: pageRoute,
                    navigateAndCloseDrawer: navigate,
                  }}
                />
              )
            )}
          </>
        </Row>
      </Drawer>
    </>
  );
};
