import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GetDatabaseTableInfoResponse } from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetDatabaseTableInfo = () => {
  const queryKey = QUERYKEYS.databaseKey.dbTableInfo();
  const url = APIURL.database;

  return useQuery<GetDatabaseTableInfoResponse, AxiosError>(queryKey, () =>
    axios.get<GetDatabaseTableInfoResponse>(url).then((r) => r.data)
  );
};

export const useClearTable = () => {
  const url = (dbKey: string) => `${APIURL.database}/${dbKey}`;
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<unknown>, AxiosError, { dbKey: string }>(
    ({ dbKey }) => axios.delete(url(dbKey)),
    {
      onSuccess: () => {
        queryClient.refetchQueries(QUERYKEYS.databaseKey.lists());
      },
    }
  );
};
