import { Form, InputNumber, Space } from "antd";
import { BasicInput, BasicTitle, CenterCol } from "../../../../components";
import { style } from "typestyle";

const inputField = style({ width: "70%" });
export const AudioSettings = () => {
  return (
    <CenterCol span={8}>
      <BasicTitle>MP3 Einstellungen</BasicTitle>
      <Space>
        <Form.Item
          label="Audio Bitrate in kbit"
          name="audioBitrate"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber className={inputField} />
        </Form.Item>
        <Form.Item
          label="Audio Channels"
          name="audioChannels"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber className={inputField} />
        </Form.Item>
      </Space>
      <BasicTitle level={4}>MP3 Tags</BasicTitle>
      <Form.Item
        label="Title"
        name={["mp3Tags", "title"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <BasicInput />
      </Form.Item>
      <Form.Item
        label="Von"
        name={["mp3Tags", "artist"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <BasicInput />
      </Form.Item>
      <Form.Item
        label="Album"
        name={["mp3Tags", "album"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <BasicInput />
      </Form.Item>
      <Form.Item
        label="Art"
        name={["mp3Tags", "genre"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <BasicInput />
      </Form.Item>
      <Form.Item
        label="Track Nr"
        name={["mp3Tags", "trackNr"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <BasicInput />
      </Form.Item>
      <Form.Item
        label="Jahr"
        name={["mp3Tags", "year"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <BasicInput />
      </Form.Item>
    </CenterCol>
  );
};
