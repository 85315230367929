import { Button, Col, Divider, List } from "antd";
import { BasicParagraph, CenterRow } from "../../../components";
import { useAuth } from "../../../providers/CustomKeycloakProvider";
import { Role } from "../../../types/enums";

export const Account = () => {
  const { userHasRole, activeUser, accountManagement } = useAuth();

  return (
    <>
      <CenterRow>
        <Col span={20}>
          <BasicParagraph>
            Dein Account wird zentral über einen Provider gemanaged.
          </BasicParagraph>
          <BasicParagraph>
            Klicke den Account Button, um deine Daten Ändern.
          </BasicParagraph>
          <Button onClick={accountManagement}>Account</Button>
        </Col>
        {userHasRole(Role.VIEWCONVERTER) && (
          <>
            <Divider />
            <Col span={20}>
              <BasicParagraph>
                Roles:
                <List size="small">
                  {activeUser?.roles.map((role) => (
                    <List.Item key={role}>{role}</List.Item>
                  ))}
                </List>
              </BasicParagraph>
            </Col>
          </>
        )}
      </CenterRow>
    </>
  );
};
