import { notification, Typography } from "antd";

export const ErrorNotification = (
  message: string,
  description: any[],
  duration = 0
) =>
  notification.error({
    message,
    description: (
      <>
        {description.map((d, i) => (
          <Typography.Paragraph key={i}>{d}</Typography.Paragraph>
        ))}
      </>
    ),
    duration,
  });

export const SuccessNotification = (
  message: string,
  description: string[],
  duration = 10
) =>
  notification.success({
    message,
    description: (
      <>
        {description.map((d, i) => (
          <Typography.Paragraph key={i}>{d}</Typography.Paragraph>
        ))}
      </>
    ),
    duration,
  });
