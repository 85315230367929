import { Button, Divider } from "antd";
import {
  BasicParagraph,
  BasicTitle,
  CenterCol,
  CenterRow,
} from "../../../components";
import { useAuth } from "../../../providers/CustomKeycloakProvider";

export const Home = () => {
  const { activeUser, login } = useAuth();

  return (
    <CenterRow>
      <CenterCol defaultResponsive centerize={false}>
        <BasicTitle>Willkommen</BasicTitle>
        {!activeUser ? (
          <>
            <Divider />
            <BasicParagraph>
              Um auf die Mediathek zuzugreifen, musst du dich Einloggen.
            </BasicParagraph>
            <BasicParagraph>
              Wenn du noch keinen Account erstellt hast, kannst du das tun indem
              du hier auf Login klickst, und dann im nächsten Fenster unten auf
              "Registrieren" klickst.
            </BasicParagraph>
            <Divider />
            <Button onClick={login}>Login</Button>
          </>
        ) : (
          <>
            <Divider />
            <BasicParagraph>
              Hallo {activeUser.name}.<br />
              Wenn du Hilfe brauchst oder Fragen hast, melde dich direkt bei
              Manuel Brenner.
            </BasicParagraph>
          </>
        )}
      </CenterCol>
    </CenterRow>
  );
};
