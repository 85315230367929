import { Divider } from "antd";
import { CenterCol } from "..";
import { APIURL } from "../../apiConnection/apiUtils/apiUrl";
import { MP4 } from "../../types/entities/mp4.entity";
import { MediaDownloadButton } from "../inputs/MediaDownloadButton";
import { MediaPlayer } from "./MediaPlayer";

interface VideoBlockProps {
  mp4: MP4;
}
export const VideoBlock: React.FC<VideoBlockProps> = ({ mp4 }) => {
  return (
    <>
      <CenterCol span={24}>
        <MediaPlayer
          mediaUrl={APIURL.stream(mp4.fileName)}
          title={mp4.fileName}
          type="video"
        />
      </CenterCol>
      <CenterCol span={24}>
        <MediaDownloadButton fileName={mp4.fileName} fileType="Video" />
      </CenterCol>
      <Divider />
    </>
  );
};
