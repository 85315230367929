import { Col, Divider, List } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { CenterRow, MediaItemCard } from "../../../components";
import { MediaRoot } from "../../../types/entities/media.entity";

interface MediaListContentProps {
  mediaList: MediaRoot[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  loading: boolean;
  showSummaries: boolean;
}

export const MediaListContent: React.FC<MediaListContentProps> = ({
  mediaList,
  fetchNextPage,
  hasNextPage,
  loading,
  showSummaries,
}) => {
  return (
    <CenterRow id="scrolldiv">
      <Col xs={24} md={24} xxl={16}>
        {loading && <MediaItemCard loading title="Suche Dateien..." />}
        {!loading && (
          <InfiniteScroll
            dataLength={mediaList.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<MediaItemCard loading title="Lade mehr Dateien..." />}
            endMessage={<Divider plain>Ende der Suche.</Divider>}
            scrollThreshold={1}
          >
            <List
              renderItem={(media) => (
                <List.Item key={media.mediaId}>
                  <MediaItemCard media={media} showSummaries={showSummaries} />
                </List.Item>
              )}
              dataSource={mediaList}
            />
          </InfiniteScroll>
        )}
      </Col>
    </CenterRow>
  );
};
