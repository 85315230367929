import { Col, ColProps } from "antd";
import { classes, style } from "typestyle";

interface CenterColProps extends ColProps {
  centerize?: boolean;
  defaultResponsive?: boolean;
}

const centerizedCol = style({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

/**
 * A default responsive col.
 * Has a span of `24 in xs, sm` and `20 in md and above`, if defaultResponsive is true
 * @param ColProps colProps can be set.
 * @param centerize all content with flex
 * @param defaultResponsive set the default responsives
 */
export const CenterCol: React.FC<CenterColProps> = ({
  children,
  centerize = true,
  defaultResponsive = false,
  ...colProps
}) => {
  const xs = defaultResponsive ? 24 : colProps.xs;
  const md = defaultResponsive ? 20 : colProps.md;

  return (
    <Col
      {...colProps}
      xs={xs}
      md={md}
      className={classes(centerize && centerizedCol)}
    >
      {children}
    </Col>
  );
};
