import axios from "axios";
import { ErrorNotification } from "../components";
import { parseApiError } from "../utils/helperFuncs";
import { useAuth } from "./CustomKeycloakProvider";

export const AxiosConfigProvider: React.FC = ({ children }) => {
  const { login, getAuthHeader } = useAuth();

  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(
    (request) => {
      request.headers = {
        ...getAuthHeader(),
      };
      return request;
    },
    (error) => {
      throw error;
    }
  );

  axios.interceptors.response.use(
    (d) => {
      return d;
    },
    (error) => {
      const { message, statusCode, statusText } = parseApiError(error);
      if (statusCode === 401) {
        login();
        throw error;
      }
      ErrorNotification(
        `${statusCode} - ${statusText}`,
        [`${error?.response?.request?.responseURL || ""}`, message],
        5
      );
      throw error;
    }
  );

  return <>{children}</>;
};
