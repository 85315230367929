import axios, { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { CreateConvertionPayload } from "../types/requestTypes";
import {
  CreateConvertJobResponse,
  GetConverterResponse,
} from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetConverter = (automaticRefetch: boolean) => {
  const url = APIURL.converter;
  const queryKey = automaticRefetch
    ? QUERYKEYS.converterKeys.withRefetch()
    : QUERYKEYS.converterKeys.list();

  return useQuery<GetConverterResponse, AxiosError>(
    queryKey,
    () => axios.get<GetConverterResponse>(url).then((r) => r.data),
    {
      refetchInterval: automaticRefetch ? 2000 : false,
    }
  );
};

export const useCreateConvertJob = () => {
  const url = APIURL.converterConvert;

  return useMutation<
    CreateConvertJobResponse,
    AxiosError,
    CreateConvertionPayload
  >((payload) =>
    axios.post<CreateConvertJobResponse>(url, payload).then((res) => res.data)
  );
};

export const useCreateMergeJob = () => {
  const url = APIURL.converterMerge;

  return useMutation<void, AxiosError, CreateConvertionPayload>((payload) =>
    axios.post<void>(url, payload).then((res) => res.data)
  );
};

export const useDeleteConvertJob = () => {
  return useMutation<void, AxiosError, string>((id) =>
    axios.delete<void>(APIURL.converterId(id)).then((res) => res.data)
  );
};
