export enum LogScope {
  // LOG = 'log',
  ERROR = "error",
  WARN = "warn",
  // DEBUG = 'debug',
  // VERBOSE = 'verbose',
  // INFO = 'info',
  // MP3 = 'mp3',
  // MP4 = 'mp4',
  // PDF = 'pdf',
  USER = "user",
  OBSERVER = "observer",
  FILEWORKER = "fileworker",
  CONVERTER = "converter",
  // DOWNLOAD = 'download',
  // STREAM = 'stream',
  TRACK = "track",
  AI = "ai",
}

export enum FileType {
  AUDIO = "audio",
  VIDEO = "video",
  OTHER = "other",
}

export enum Role {
  VIEWMEDIAS = "viewMedias",
  VIEWOBSERVER = "viewObserver",
  VIEWLOGS = "viewLogs",
  VIEWDATABASE = "viewDatabase",
  VIEWUNTRACKEDFILES = "viewUntrackedFiles",
  PATCHMP3 = "patchMp3",
  VIEWINFOS = "viewInfos",
  ACCOUNT = "viewAccount",
  VIEWCONVERTER = "viewConverter",
  CREATETRANSCRIPT = "createTranscript",
  VIEWTRANSCRIPTIONS = "viewTranscriptions",
}

export enum SubScope {
  DOWNLOAD = "Download",
  SEARCH = "Search",
  WATCH = "Watch",
  NONE = "none",
}

export enum FFMPEGPreset {
  ULTRAFAST = "ultrafast",
  SUPERFAST = "superfast",
  VERYFAST = "veryfast",
  FASTER = "faster",
  FAST = "fast",
  MEDIUM = "medium",
  SLOW = "slow",
  SLOWER = "slower",
  VERYSLOW = "veryslow",
}

export enum FFMPEGOutputFormat {
  MP3 = "mp3",
  MP4 = "mp4",
}
export enum ProcessedFileTargetDestination {
  INPUT = "input",
  DONE = "done",
}

export enum ConvertType {
  CONVERT = "convert",
  MERGE = "merge",
}
