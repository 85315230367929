import { Divider } from "antd";
import {
  BasicTitle,
  BasicParagraph,
  MediaDownloadButton,
  CenterCol,
  CenterRow,
} from "../../../../components";
import { MediaRoot } from "../../../../types/entities/media.entity";
import { useAuth } from "../../../../providers/CustomKeycloakProvider";
import { Role } from "../../../../types/enums";

interface MoreContentProps {
  mediaRoot: MediaRoot | undefined;
}

export const MoreContent: React.FC<MoreContentProps> = ({ mediaRoot }) => {
  const { userHasRole } = useAuth();
  const pdfs = mediaRoot?.pdf?.length ? mediaRoot.pdf : undefined;
  const pptxs = mediaRoot?.pptx?.length ? mediaRoot.pptx : undefined;
  const txt = mediaRoot?.txt?.length ? mediaRoot.txt : undefined;
  return (
    <CenterRow gutter={[0, 12]}>
      <CenterCol span={24}>
        <BasicTitle level={5}>Weitere Dateien</BasicTitle>
        <Divider>PDFs</Divider>
        {!pdfs ? (
          <BasicParagraph>Keine PDFs vorhanden.</BasicParagraph>
        ) : (
          pdfs.map((pdf) => (
            <MediaDownloadButton
              fileName={pdf.fileName}
              fileType="PDF"
              key={pdf.id}
            />
          ))
        )}
        <Divider>PowerPoints</Divider>
        {!pptxs ? (
          <BasicParagraph>Keine PowerPoints vorhanden.</BasicParagraph>
        ) : (
          pptxs.map((pptx) => (
            <MediaDownloadButton
              fileName={pptx.fileName}
              fileType="PPTX"
              key={pptx.id}
            />
          ))
        )}
        {userHasRole([Role.VIEWTRANSCRIPTIONS]) && (
          <>
            <Divider>Transkription</Divider>
            {!txt ? (
              <BasicParagraph>
                Keine .txt Transkriptionen vorhanden.
              </BasicParagraph>
            ) : (
              txt.map((txt) => (
                <MediaDownloadButton
                  fileName={txt.fileName}
                  fileType="TXT"
                  key={txt.id}
                />
              ))
            )}
          </>
        )}
      </CenterCol>
    </CenterRow>
  );
};
