import axios from "axios";
import React from "react";
import { Navigate } from "react-router";
import { APIURL } from "../apiConnection/apiUtils/apiUrl";
import { ApplicationErrorComponent } from "../components/feedback/ApplicationErrorComponent";

interface ErrorBoundaryProps {
  level: "application" | "router";
  error?: Error;
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
}

export class CustomErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: props.error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error });

    // if (process.env.NODE_ENV !== "development") {
    axios.post(APIURL.log, {
      errorMessage: error.message,
      componentStack: errorInfo?.componentStack,
    });
    // }
  }

  render() {
    const { error } = this.state;
    const { level, children } = this.props;

    if (error) {
      if (level === "application") {
        return <ApplicationErrorComponent error={error} />;
      }

      this.setState({ error: undefined });
      return <Navigate state={error} to={{ pathname: "/error" }} />;
    }

    return children;
  }
}
