import { Divider, Tabs } from "antd";
import { ConverterForm } from "./convert/ConverterForm";
import { ConverterJobs } from "./convert/ConverterJobs";
import { MergerForm } from "./merge/MergerForm";
import { useGetStreamKey } from "../../../apiConnection/StreamKeyConnection";

export const ConverterPage = () => {
  useGetStreamKey();
  return (
    <>
      <Tabs
        style={{ width: "100%" }}
        defaultActiveKey="converter"
        centered
        items={[
          {
            key: "converter",
            label: "Konvertieren",
            children: <ConverterForm />,
          },
          {
            key: "merger",
            label: "Zusammenfügen",
            children: <MergerForm />,
          },
        ]}
      />
      <Divider />
      <ConverterJobs />
    </>
  );
};
