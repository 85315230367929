import { Table } from "antd";
import { style } from "typestyle";
import { MP3 } from "../../types/entities/mp3.entity";

interface MP3DetailTableProps {
  mp3Data: MP3;
}

const table = style({
  width: "100%",
});

export const MP3DetailTable: React.FC<MP3DetailTableProps> = ({ mp3Data }) => {
  if (!mp3Data) return null;
  return (
    <>
      <Table
        className={table}
        dataSource={[{ ...mp3Data, key: mp3Data.fileName }]}
        columns={[
          { title: "Titel", dataIndex: "title", key: "title" },
          { title: "Von", dataIndex: "artist", key: "artist" },
          { title: "Album", dataIndex: "album", key: "album" },
          { title: "Art", dataIndex: "genre", key: "genre" },
        ]}
        pagination={false}
        scroll={{ x: 550 }}
      />
    </>
  );
};
