import { Card, CardProps, Col, Row, Space, Typography } from "antd";
import { style } from "typestyle";
import {
  BasicParagraph,
  BasicTitle,
  MP3EditModal,
  SimpleDescription,
} from "..";
import { UntrackedFileEditModal } from "../../pages/protected/untrackedFiles/UntrackedFileEditModal";
import { MP3 } from "../../types/entities/mp3.entity";
import { UntrackedFile } from "../../types/entities/untrackedFile.entity";

interface EditableMP3DetailCardProps extends CardProps {
  mp3: MP3 | UntrackedFile;
}

const mp3FileCard = style({
  width: "100%",
  boxShadow: "0px 0px 6px #D9D9D9",
  borderRadius: "10px",
  marginBottom: "15px",
});

export const EditableMP3DetailCard: React.FC<EditableMP3DetailCardProps> = ({
  mp3,
  ...cardProps
}) => {
  let isMp3 = false;
  if ((mp3 as MP3).mediaRoot) {
    isMp3 = true;
  }

  return (
    <>
      <Card
        {...cardProps}
        className={mp3FileCard}
        title={
          <BasicTitle leftAligned isSubTitle style={{ whiteSpace: "normal" }}>
            <BasicParagraph key={mp3.id} paddingOff>
              {mp3.relativeFilePath}
            </BasicParagraph>
          </BasicTitle>
        }
        size="small"
        extra={
          <Space size="middle">
            {isMp3 ? (
              <MP3EditModal mp3={mp3 as MP3} />
            ) : (
              <UntrackedFileEditModal untrackedFile={mp3} />
            )}
          </Space>
        }
      >
        <Row gutter={[0, { xs: 10, sm: 10 }]}>
          <Col xs={24} lg={8}>
            <SimpleDescription title="Title" paddingOff>
              {mp3.title || (
                <Typography.Text type="danger">KEINE DATEN</Typography.Text>
              )}
            </SimpleDescription>
          </Col>
          <Col xs={24} lg={4}>
            <SimpleDescription title="Von" paddingOff>
              {mp3.artist || (
                <Typography.Text type="danger">KEINE DATEN</Typography.Text>
              )}
            </SimpleDescription>
          </Col>
          <Col xs={24} lg={4}>
            <SimpleDescription title="Art" paddingOff>
              {mp3.genre || (
                <Typography.Text type="danger">KEINE DATEN</Typography.Text>
              )}
            </SimpleDescription>
          </Col>
          <Col xs={24} lg={4}>
            <SimpleDescription title="Veranstaltung" paddingOff>
              {mp3.album || (
                <Typography.Text type="danger">KEINE DATEN</Typography.Text>
              )}
            </SimpleDescription>
          </Col>
          <Col xs={24} lg={2}>
            <SimpleDescription title="Jahr" paddingOff>
              {mp3.year || (
                <Typography.Text type="danger">KEINE DATEN</Typography.Text>
              )}
            </SimpleDescription>
          </Col>
          <Col xs={24} lg={2}>
            <SimpleDescription title="Track Nr" paddingOff>
              {mp3.trackNr || (
                <Typography.Text type="danger">KEINE DATEN</Typography.Text>
              )}
            </SimpleDescription>
          </Col>
        </Row>
      </Card>
    </>
  );
};
