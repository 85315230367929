import axios, { AxiosError } from "axios";
import { useInfiniteQuery } from "react-query";
import { LogScope, SubScope } from "../types/enums";
import { GetLogsResponse } from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetLogs = (scopes: {
  logScope: LogScope;
  logSubScope: SubScope;
}) => {
  const scopeQuery = `logscope=${scopes.logScope}&subscope=${scopes.logSubScope}`;
  const getLogsWithPageQuery = (page: number) => {
    return `${APIURL.log}?${scopeQuery}&page=${page}`;
  };
  const queryKey = QUERYKEYS.logKey.logListQuery(scopeQuery);

  return useInfiniteQuery<GetLogsResponse, AxiosError>(
    queryKey,
    ({ pageParam = 0 }) =>
      axios
        .get<GetLogsResponse>(getLogsWithPageQuery(pageParam))
        .then((r) => r.data),
    {
      getNextPageParam: (logList) => logList.nextPage,
      staleTime: 0,
    }
  );
};
