import { BasicParagraph } from "..";

interface SimpleDescriptionProps {
  paddingOff?: boolean;
  title: string;
}

export const SimpleDescription: React.FC<SimpleDescriptionProps> = ({
  children,
  title,
  paddingOff,
}) => {
  return (
    <>
      <BasicParagraph type="secondary" paddingOff={paddingOff}>
        {title}
      </BasicParagraph>
      {children}
    </>
  );
};
