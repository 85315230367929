import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { APIURL } from "./apiUtils/apiUrl";

export const useGetStreamKey = () => {
  const url = APIURL.streamKey;

  return useQuery("streamkey", () => axios.get(url), {
    staleTime: 1000 * 60 * 60,
  });
};

export const useDeleteStreamkey = () => {
  const url = APIURL.streamKey;

  return (headers: Record<string, string>) =>
    axios.delete<any, AxiosResponse<{ streamKeyDeleted: boolean }>>(url, {
      headers,
    });
};
