import { Divider, Space } from "antd";
import {
  CenterCol,
  MediaDownloadButton,
  MediaPlayer,
  MP3DetailTable,
  MP3EditModal,
} from "..";
import { APIURL } from "../../apiConnection/apiUtils/apiUrl";
import { useAuth } from "../../providers/CustomKeycloakProvider";
import { MP3 } from "../../types/entities/mp3.entity";
import { Role } from "../../types/enums";
import { CreateTranscriptModal } from "../modal/CreateTranscriptModal";

interface AudioBlockProps {
  mp3: MP3;
}
export const AudioBlock: React.FC<AudioBlockProps> = ({ mp3 }) => {
  const { userHasRole: hasRole } = useAuth();

  return (
    <>
      <CenterCol span={24}>
        <MediaPlayer
          mediaUrl={APIURL.stream(mp3.fileName)}
          title={`${mp3.title} - ${mp3.artist}`}
          type="audio"
        />
      </CenterCol>
      <CenterCol span={24}>
        <MP3DetailTable mp3Data={mp3} />
      </CenterCol>
      <CenterCol span={24}>
        <Space>
          {hasRole([Role.PATCHMP3]) && <MP3EditModal mp3={mp3} />}
          {hasRole([Role.CREATETRANSCRIPT]) && (
            <CreateTranscriptModal mp3={mp3} />
          )}
        </Space>
      </CenterCol>

      <CenterCol span={24}>
        <MediaDownloadButton fileName={mp3.fileName} fileType="Audio" />
      </CenterCol>
      <Divider />
    </>
  );
};
