import React from "react";
import {
  DatabaseIndex,
  Info,
  Logs,
  MediaDetailIndex,
  MediaIndex,
  Observer,
  UntrackedFiles,
} from "../pages/protected";
import { Account } from "../pages/protected/account/Account";
import { ConverterPage } from "../pages/protected/converter/ConverterPage";
import { Home } from "../pages/public";
import { PathParam } from "../types/clientEnums";
import { Role } from "../types/enums";

export type PageRoute = {
  url: string;
  param: PathParam;
  displayName: string;
  accessRole?: Role;
  component: React.ReactNode;
};

const Urls = {
  home: "/" as const,
  observer: "/observer" as const,
  medias: "/medias" as const,
  mediaId: () => `${Urls.medias}/:${PathParam.MEDIAID}` as const,
  logs: "/logs" as const,
  database: "/database" as const,
  untracked: "/untracked" as const,
  info: "/info" as const,
  account: "/account" as const,
  converter: "/converter" as const,
};

export const PageRoutes: Record<PathParam, PageRoute> = {
  [PathParam.HOME]: {
    url: Urls.home,
    param: PathParam.HOME,
    displayName: "Home",
    component: <Home />,
  },
  [PathParam.OBSERVER]: {
    url: Urls.observer,
    param: PathParam.OBSERVER,
    displayName: "Observer",
    accessRole: Role.VIEWOBSERVER,
    component: <Observer />,
  },
  [PathParam.MEDIAS]: {
    url: Urls.medias,
    param: PathParam.MEDIAS,
    displayName: "Mediathek",
    accessRole: Role.VIEWMEDIAS,
    component: <MediaIndex />,
  },
  [PathParam.MEDIAID]: {
    url: Urls.mediaId(),
    param: PathParam.MEDIAID,
    displayName: "Media Infos",
    accessRole: Role.VIEWMEDIAS,
    component: <MediaDetailIndex />,
  },
  [PathParam.LOGS]: {
    url: Urls.logs,
    param: PathParam.LOGS,
    displayName: "Logs",
    accessRole: Role.VIEWLOGS,
    component: <Logs />,
  },
  [PathParam.DATABASE]: {
    url: Urls.database,
    param: PathParam.DATABASE,
    displayName: "Database",
    accessRole: Role.VIEWDATABASE,
    component: <DatabaseIndex />,
  },
  [PathParam.UNTRACKED]: {
    url: Urls.untracked,
    param: PathParam.UNTRACKED,
    displayName: "Untracked Files",
    accessRole: Role.VIEWUNTRACKEDFILES,
    component: <UntrackedFiles />,
  },
  [PathParam.INFO]: {
    url: Urls.info,
    param: PathParam.INFO,
    displayName: "Infos",
    accessRole: Role.VIEWINFOS,
    component: <Info />,
  },
  [PathParam.ACCOUNT]: {
    url: Urls.account,
    param: PathParam.ACCOUNT,
    displayName: "Account Einstellungen",
    accessRole: Role.ACCOUNT,
    component: <Account />,
  },
  [PathParam.CONVERTER]: {
    url: Urls.converter,
    param: PathParam.CONVERTER,
    displayName: "Converter",
    // accessRole: Role.VIEWCONVERTER,
    component: <ConverterPage />,
  },
};
