import { Card, Col, Row } from "antd";
import { useGetDatabaseTableInfo } from "../../../apiConnection/DatabaseConnection";
import {
  BasicTitle,
  CenterCol,
  CenterRow,
  LoadingWrapper,
  SimpleDescription,
} from "../../../components";
import { ApiErrorComponent } from "../../../components/feedback/ApiErrorComponent";
import { GetDatabaseTableInfoResponse } from "../../../types/responseTypes";
import { TableClearModal } from "./DatabaseDropModal";

export const DatabaseIndex = () => {
  const { isLoading, error, data: tableInfo } = useGetDatabaseTableInfo();

  if (isLoading) {
    return <LoadingWrapper />;
  }

  if (error) {
    return <ApiErrorComponent apiError={error} />;
  }

  return (
    <CenterRow gutter={[0, 16]}>
      {tableInfo &&
        Object.entries(tableInfo).map(([key, value]) => (
          <CenterCol span={24} centerize={false} key={key}>
            <Card
              style={{ width: "100%" }}
              title={
                <BasicTitle
                  leftAligned
                  isSubTitle
                  style={{ whiteSpace: "normal" }}
                >
                  {key}
                </BasicTitle>
              }
              size="small"
            >
              <Row gutter={[0, { xs: 10, sm: 10 }]}>
                <Col xs={12}>
                  <SimpleDescription title="Count">{value}</SimpleDescription>
                </Col>
                <Col xs={12}>
                  <TableClearModal
                    dbKey={key as keyof GetDatabaseTableInfoResponse}
                  />
                </Col>
              </Row>
            </Card>
          </CenterCol>
        ))}
    </CenterRow>
  );
};
