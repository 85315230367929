import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Select } from "antd";
import {
  useCreateMergeJob,
  useGetConverter,
} from "../../../../apiConnection/ConverterConnection";
import { BasicInput, CenterRow } from "../../../../components";
import {
  ConvertType,
  ProcessedFileTargetDestination,
} from "../../../../types/enums";
import { CreateConvertionPayload } from "../../../../types/requestTypes";
import { InitialConverterPayload } from "../ConverterUtils";

export const MergerForm = () => {
  const {
    data: converterData,
    // isLoading,
    // error: converterDataError,
    // refetch: refetchConverter,
  } = useGetConverter(false);

  const { mutate: mergeFiles } = useCreateMergeJob();

  const [form] = Form.useForm<CreateConvertionPayload>();
  const mergeNames = Form.useWatch("mergeNames", form);

  const submit = (formData: CreateConvertionPayload) => {
    const payload = {
      ...InitialConverterPayload,
      ...formData,
      fadeIn: false,
      fadeOut: false,
      processedFileTargetDestination: ProcessedFileTargetDestination.INPUT,
      convertType: ConvertType.MERGE,
    } as CreateConvertionPayload;
    mergeFiles(payload);
  };

  return (
    <CenterRow>
      <Col span={24}>
        <Form onFinish={submit} style={{ width: "100%" }} form={form}>
          <Form.List name="mergeNames">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Form.Item label="Datei" required={false} key={field.key}>
                    <Form.Item {...field} noStyle rules={[{ required: true }]}>
                      <Select style={{ width: "90%" }}>
                        {converterData?.fileNameList
                          .filter(
                            (fileName) =>
                              fileName.toLowerCase().endsWith(".mp4") ||
                              fileName.toLowerCase().endsWith(".mts")
                          )
                          .map((file) => (
                            <Select.Option key={file}>{file}</Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    {fields.length > 2 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "90%" }}
                    icon={<PlusOutlined />}
                  >
                    Datei Anhängen
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Output Dateiname (ohne extension)"
            name="outputName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <BasicInput />
          </Form.Item>
          <Form.Item>
            <Button
              disabled={!mergeNames || mergeNames.length < 2}
              type="primary"
              htmlType="submit"
            >
              Zusammenfügen
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </CenterRow>
  );
};
