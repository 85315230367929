import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { ObserverActionRequest } from "../types/requestTypes";
import { GetObserverStatusResponse } from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetObserverStatus = (automaticRefetch: boolean) => {
  const url = APIURL.observerStatus;
  const queryKey = QUERYKEYS.observerKeys.status();

  return useQuery<GetObserverStatusResponse, AxiosError>(
    queryKey,
    () => axios.get<GetObserverStatusResponse>(url).then((r) => r.data),
    {
      refetchInterval: automaticRefetch ? 1000 : false,
    }
  );
};

export const useCommandObserver = () => {
  const url = APIURL.observerStatus;

  return useMutation<AxiosResponse<void>, AxiosError, ObserverActionRequest>(
    async (action) => axios.post<void>(url, action)
  );
};
