import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { useEffect, useMemo } from "react";
import { URLSearchParamsInit, useNavigate } from "react-router-dom";
import { style } from "typestyle";
import { useGetFiltertags } from "../../../apiConnection/FiltertagsConnection";
import { BasicText, CenterCol, CenterRow } from "../../../components";
import { MediaQueryArrays } from "../../../types/requestTypes";
import { GetDistinctMp3TagsResponse } from "../../../types/responseTypes";
import { PageRoutes } from "../../../utils/pageRoutes";

interface MediaListFormProps {
  initialValues: MediaQueryArrays;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
}

const emptyForm: MediaQueryArrays = {
  artists: undefined,
  albums: undefined,
  years: undefined,
  genres: undefined,
  fuzzy: undefined,
  files: undefined,
  sortOrder: "DESC",
};

const filterCollapse = style({
  marginBottom: "1.5rem",
});

export const MediaListForm: React.FC<MediaListFormProps> = ({
  initialValues,
  setSearchParams,
}) => {
  const { data: filterTags } = useGetFiltertags();

  const { artists, genres, albums, years } =
    useMemo<GetDistinctMp3TagsResponse>(() => {
      if (!filterTags) {
        return { artists: [], genres: [], albums: [], years: [] };
      }
      return {
        artists: filterTags.artists,
        genres: filterTags.genres,
        albums: filterTags.albums,
        years: filterTags.years,
      };
    }, [filterTags]);

  const [form] = Form.useForm<MediaQueryArrays>();
  const navigate = useNavigate();

  const fireSearch = (values: MediaQueryArrays) => {
    setSearchParams({
      artists: values.artists?.map((a) => encodeURI(a)) || [],
      albums: values.albums || [],
      years: values.years?.map((y) => String(y)) || [],
      genres: values.genres || [],
      fuzzy:
        values.fuzzy?.map((f) =>
          f.replace(/^\s*|\s*$|/g, "").replace(/\s{2,}/g, " ")
        ) || [],
      files: values.files || [],
      sortOrder: values.sortOrder || "",
    });
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const resetForm = () => {
    form.setFieldsValue(emptyForm);
    navigate(`${PageRoutes.medias.url}`);
  };

  return (
    <CenterRow>
      <CenterCol md={24} xl={16} defaultResponsive centerize={false}>
        <Form
          onFinish={fireSearch}
          form={form}
          initialValues={initialValues}
          colon={false}
          layout="vertical"
        >
          <Form.Item
            label={
              <Space>
                <BasicText>Wortsuche </BasicText>
                <Tooltip title="Mehrere Wörter möglich. Wortsuche ist eine UND suche, und durchsucht die Titel der MP3s.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            }
            name="fuzzy"
          >
            <Select mode="tags" notFoundContent="" size="large" allowClear />
          </Form.Item>
          <Collapse className={filterCollapse}>
            <CollapsePanel
              header={
                <Space>
                  <BasicText>Filter</BasicText>
                  <Tooltip title="Fiter sind aktiv, auch wenn sie eingeklappt sind. Mehrere Selects in einer Kategorie sind mit ODER, die einzelnen Kategorien sind mit UND gefiltert.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }
              key={1}
            >
              <Form.Item label="Von" name="artists">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Klicken oder eingeben."
                >
                  {artists.map((a) => (
                    <Select.Option key={a}>{a}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Veranstaltung" name="albums">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Klicken oder eingeben."
                >
                  {albums.map((a) => (
                    <Select.Option key={a}>{a}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Art" name="genres">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Klicken oder eingeben."
                >
                  {genres.map((g) => (
                    <Select.Option key={g}>{g}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Jahr" name="years">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Klicken oder eingeben."
                >
                  {years.map((y) => (
                    <Select.Option key={y}>{y}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="files"
                label="Nur anzeigen wenn folgende Dateien vorhanden sind"
              >
                <Checkbox.Group>
                  <Row gutter={16}>
                    <Col>
                      <Checkbox value="mp3" style={{ lineHeight: "32px" }}>
                        Audio
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value="mp4" style={{ lineHeight: "32px" }}>
                        Video
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value="pdf" style={{ lineHeight: "32px" }}>
                        PDF
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item name="sortOrder" label="Sortieren">
                <Radio.Group>
                  <Radio value="DESC">Aktuellste zuerst</Radio>
                  <Radio value="ASC">Ältesten zuerst</Radio>
                </Radio.Group>
              </Form.Item>
            </CollapsePanel>
          </Collapse>
          <Form.Item>
            <Space size="large">
              <Button type="primary" shape="round" htmlType="submit">
                Suchen
              </Button>
              <Button type="dashed" shape="round" onClick={resetForm}>
                Suche Zurücksetzen
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </CenterCol>
    </CenterRow>
  );
};
