import axios, { AxiosError } from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import {
  GetMediaDetailsResponse,
  GetMediaListResponse,
} from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetMediaList = (searchParams: string) => {
  const mediaListUrlWithQuery = (page: number) => {
    return `${APIURL.medias}${
      searchParams || "?"
    }&page=${page}&limit=10`.replace(/\?&/g, "?");
  };

  const queryKey = QUERYKEYS.mediaKeys.mediaListQuery(searchParams);

  return useInfiniteQuery<GetMediaListResponse, AxiosError>(
    queryKey,
    ({ pageParam = 0 }) =>
      axios
        .get<GetMediaListResponse>(mediaListUrlWithQuery(pageParam))
        .then((r) => r.data),
    {
      getNextPageParam: (fileIdList) => fileIdList.nextPage,
    }
  );
};

export const useGetMediaDetails = (mediaId: string) => {
  const url = APIURL.mediaId(mediaId);
  const queryKey = QUERYKEYS.mediaKeys.detail(mediaId);

  return useQuery<GetMediaDetailsResponse, AxiosError>(queryKey, () =>
    axios.get<GetMediaDetailsResponse>(url).then((r) => r.data)
  );
};
