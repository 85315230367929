import { Col, Skeleton } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useGetMediaDetails } from "../../../../apiConnection/MediaConnection";
import { useGetStreamKey } from "../../../../apiConnection/StreamKeyConnection";
import {
  BasicTitle,
  CenterCol,
  CenterRow,
  LoadingWrapper,
} from "../../../../components";
import { MoreContent } from "./MoreContent";
import { Summary } from "./Summary";
import { MP4s } from "./MP4s";
import { MP3s } from "./MP3s";

interface MediaDetailsProps {
  mediaId: string;
}

export const MediaDetails: React.FC<MediaDetailsProps> = ({ mediaId }) => {
  useGetStreamKey();
  const { data: mediaRoot, isLoading } = useGetMediaDetails(mediaId);
  const { xs } = useBreakpoint();
  const mp3s = mediaRoot?.mp3?.length ? mediaRoot.mp3 : undefined;
  const mp4s = mediaRoot?.mp4?.length ? mediaRoot.mp4 : undefined;
  const markDowns = mediaRoot?.markDown?.length
    ? mediaRoot.markDown
    : undefined;

  return (
    <>
      {!mediaRoot && !isLoading && <BasicTitle>Invalider Link</BasicTitle>}
      {!mediaRoot ? (
        <CenterRow trimmedWidth>
          <Col span={24}>
            <LoadingWrapper message="Lade Dateien" isLoading={isLoading}>
              <Skeleton active paragraph={{ rows: 10 }} />
            </LoadingWrapper>
          </Col>
        </CenterRow>
      ) : (
        <>
          <CenterRow gutter={[xs ? 0 : 12, 0]}>
            {markDowns && <Summary markDowns={markDowns} />}
            <CenterCol xs={24} lg={20} xl={16} xxl={12}>
              <MP4s mp4s={mp4s} />
            </CenterCol>
            <CenterCol xs={24} xxl={12}>
              <MP3s mp3s={mp3s} />
              <MoreContent mediaRoot={mediaRoot} />
            </CenterCol>
          </CenterRow>
        </>
      )}
    </>
  );
};
