import { Result } from "antd";

export const NotAuthorized = () => {
  return (
    <Result
      status="403"
      title="ERROR 403"
      subTitle="Dir fehlt die Berechtigungen diese Seite anzuzeigen."
    />
  );
};
