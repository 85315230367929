import { Route, Routes } from "react-router";
import { SiteLayout } from "../pages/layout";
import { Protector } from "../pages/protected";
import { NotFound } from "../pages/public";
import { ErrorPage } from "../pages/public/ErrorPage";
import { PageRoutes } from "../utils/pageRoutes";

export const RouteProvider = () => {
  return (
    <Routes>
      <Route element={<SiteLayout />}>
        <Route element={<Protector routeOutlet />}>
          {Object.values(PageRoutes).map((pageRoute, i) => (
            <Route
              key={i}
              path={pageRoute.url}
              element={
                <Protector accessRoles={pageRoute.accessRole}>
                  {pageRoute.component}
                </Protector>
              }
            />
          ))}
        </Route>
        <Route path={"/error"} element={<ErrorPage />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};
