import { Button, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import {
  useCommandObserver,
  useGetObserverStatus,
} from "../../../apiConnection/ObserverConnection";
import { LoadingWrapper } from "../../../components";
import { ApiErrorComponent } from "../../../components/feedback/ApiErrorComponent";
import { ObserverActionRequest } from "../../../types/requestTypes";
import { parseApiError } from "../../../utils/helperFuncs";

export const Observer = () => {
  const [automaticRefetch, setAutomaticRefetch] = useState(true);
  const {
    data: observerStatus,
    isLoading,
    error: statusError,
    refetch: getStatus,
  } = useGetObserverStatus(automaticRefetch);

  useEffect(() => {
    setAutomaticRefetch(observerStatus?.watchedFolders ? true : false);
  }, [observerStatus?.watchedFolders]);

  const { mutate: commandObserver, error: actionError } = useCommandObserver();
  const handleAction = (action: ObserverActionRequest) => {
    commandObserver(action, {
      onSuccess: () => {
        getStatus();
      },
    });
  };

  if (isLoading) {
    return <LoadingWrapper />;
  }

  if (statusError) {
    return <ApiErrorComponent apiError={statusError} />;
  }

  return (
    <>
      {observerStatus && (
        <>
          <Typography>{observerStatus.message}</Typography>
          {observerStatus.watchedFolders ? (
            <Button onClick={() => handleAction({ actionType: "stop" })}>
              Stop
            </Button>
          ) : (
            <Button onClick={() => handleAction({ actionType: "start" })}>
              Start
            </Button>
          )}
          <Row style={{ width: "100%" }}>
            {observerStatus.watchedFolders && (
              <>
                <Col span={24}>
                  <Typography.Text>
                    Fileworkers queueSize is: {observerStatus.queueSize}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text>Watched Folders:</Typography.Text>
                </Col>
                {Object.keys(observerStatus.watchedFolders).map((k) => (
                  <Col span={24} key={k}>
                    <Typography.Text>{JSON.stringify(k)}</Typography.Text>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </>
      )}
      {statusError && (
        <Typography>{parseApiError(statusError).statusText}</Typography>
      )}
      {actionError && (
        <Typography>{parseApiError(actionError).statusText}</Typography>
      )}
    </>
  );
};
