import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { CenterCol, BasicTitle } from "../../../../components";
import { MarkDown } from "../../../../types/entities/markDown.entity";

interface SummaryProps {
  markDowns: Array<MarkDown>;
}

export const Summary: React.FC<SummaryProps> = ({ markDowns }) => {
  return (
    <CenterCol xs={24} lg={20} xl={16} xxl={12} centerize={false}>
      <BasicTitle level={5}>Zusammenfassung</BasicTitle>
      {markDowns
        .sort((a, b) => (a.fileName < b.fileName ? -1 : 1))
        .map((markDown) => (
          <ReactMarkdown
            children={markDown.markDownContent}
            remarkPlugins={[remarkGfm]}
            key={markDown.id}
          />
        ))}
    </CenterCol>
  );
};
