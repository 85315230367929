import { AxiosError } from "axios";
import { NotFound } from "../../pages/public";
import { NotAuthenticated } from "../../pages/public/NotAuthenticated";
import { NotAuthorized } from "../../pages/public/NotAuthorized";
import { parseApiError } from "../../utils/helperFuncs";
import { ApplicationErrorComponent } from "./ApplicationErrorComponent";

interface ApiErrorComponentProps {
  apiError: AxiosError<any, any>;
}

export const ApiErrorComponent: React.FC<ApiErrorComponentProps> = ({
  apiError,
}) => {
  const { statusCode } = parseApiError(apiError);
  switch (statusCode) {
    case 404:
      return <NotFound />;
    case 401:
      return <NotAuthenticated />;
    case 403:
      return <NotAuthorized />;
    default:
      return <ApplicationErrorComponent error={apiError} />;
  }
};
