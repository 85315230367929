import { Result } from "antd";

export const NotAuthenticated = () => {
  return (
    <Result
      status="403"
      title="ERROR 401"
      subTitle="Du musst dich anmelden um Zugriff auf diese Seite zu haben."
    />
  );
};
