import { Menu } from "antd";
import { Header } from "antd/lib/layout/layout";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";
import { useLocation } from "react-router";
import { style } from "typestyle";
import { useAuth } from "../../../providers/CustomKeycloakProvider";
import { PageRoutes } from "../../../utils/pageRoutes";
import { MenuEntries } from "./menuEntries";
import { MenuItem } from "./MenuItem";

const headerStyle = style({
  position: "fixed",
  zIndex: 1,
  width: "100%",
  background: "none",
  padding: "0",
});

const marginLeftAuto = style({
  marginLeft: "auto",
});

const marginRightAuto = style({
  marginRight: "auto",
});

export const NormalMenu: React.FC = () => {
  const { userHasRole: hasRole, login, logout, activeUser } = useAuth();
  const location = useLocation();
  const locationPath = location.pathname;
  const locationKey = [locationPath.split("/")[1]];

  return (
    <Header className={headerStyle}>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={[PageRoutes.home.param]}
        selectedKeys={
          locationPath === PageRoutes.home.url
            ? [PageRoutes.home.param]
            : locationKey
        }
        style={{ justifyContent: "center" }}
        items={[
          {
            label: <MenuItem desktop={{ pageRoute: PageRoutes.home }} />,
            key: PageRoutes.home.param,
            className: marginRightAuto,
          },
          ...MenuEntries.filter((entry) => hasRole(entry.accessRole)).map(
            (pageRoute) => {
              const entry: ItemType = {
                label: <MenuItem desktop={{ pageRoute }} />,
                key: pageRoute.param,
              };
              return entry;
            }
          ),
          {
            label: (
              <MenuItem
                actionItem={{
                  displayName: activeUser ? "Logout" : "Login",
                  onClick: activeUser ? logout : login,
                }}
              />
            ),
            key: activeUser ? "logout" : "login",
            className: marginLeftAuto,
          },
        ]}
      ></Menu>
    </Header>
  );
};
