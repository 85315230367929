import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin, SpinProps } from "antd";
import { style } from "typestyle";

const spin = style({
  justifyContent: "center",
  marginTop: "1rem",
  width: "100%",
});

export const CentralSpinner: React.FC<SpinProps> = ({
  children,
  ...spinProps
}) => {
  return (
    <Spin
      className={spin}
      indicator={<Loading3QuartersOutlined spin />}
      {...spinProps}
    >
      {children}
    </Spin>
  );
};
