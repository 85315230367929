import { Card, Row, Col, Collapse, List, Button } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { style } from "typestyle";
import {
  useDeleteConvertJob,
  useGetConverter,
} from "../../../../apiConnection/ConverterConnection";
import {
  BasicParagraph,
  BasicTitle,
  SimpleDescription,
} from "../../../../components";

const jobCard = style({
  width: "100%",
  boxShadow: "0px 0px 6px #D9D9D9",
  borderRadius: "10px",
});

export const ConverterJobs = () => {
  const { data: converterData } = useGetConverter(true);
  const { isLoading: mutationIsLoading, mutate: killJob } =
    useDeleteConvertJob();
  const currentConvertion = converterData?.currentConvertion;
  const upcomingConvertions = Object.entries(
    converterData?.upcomingConvertions || {}
  );
  const failedConvertions = Object.entries(
    converterData?.failedConvertions || {}
  );

  return (
    <>
      <BasicTitle level={4}>Laufender Job</BasicTitle>
      {!currentConvertion ? (
        <>Keiner</>
      ) : (
        <Card
          className={jobCard}
          title={
            currentConvertion.payload.convertName ||
            currentConvertion.payload.mergeNames.map((file) => `=> ${file} `)
          }
          style={{ width: "100%" }}
          extra={
            <Button
              disabled={mutationIsLoading}
              onClick={() => killJob(currentConvertion.id)}
            >
              Delete
            </Button>
          }
        >
          <Row gutter={[0, { xs: 10, sm: 10 }]}>
            <Col xs={12} lg={12}>
              <SimpleDescription title="Progress" paddingOff>
                <BasicParagraph paddingOff>
                  {currentConvertion.progress} %
                </BasicParagraph>
              </SimpleDescription>
            </Col>
            <Col xs={12} lg={12}>
              <SimpleDescription title="Output" paddingOff>
                <BasicParagraph paddingOff>
                  {currentConvertion.payload.outputName}
                </BasicParagraph>
              </SimpleDescription>
            </Col>

            <Col span={24}>
              <Collapse>
                <CollapsePanel key={currentConvertion.id} header="Payload">
                  <BasicParagraph paddingOff>
                    {JSON.stringify(currentConvertion.payload, null, 4)}
                  </BasicParagraph>
                </CollapsePanel>
              </Collapse>
            </Col>
          </Row>
        </Card>
      )}
      <BasicTitle level={4}>Warteschlange Jobs</BasicTitle>
      {upcomingConvertions?.length === 0 ? (
        <>Keine</>
      ) : (
        <List
          style={{ width: "90%" }}
          renderItem={(convertion) => (
            <List.Item key={convertion.id}>
              <Card
                title={
                  convertion.payload.convertName ||
                  convertion.payload.mergeNames.map((file) => `=> ${file} `)
                }
                key={convertion.id}
                className={jobCard}
                extra={
                  <Button
                    disabled={mutationIsLoading}
                    onClick={() => killJob(convertion.id)}
                  >
                    Delete
                  </Button>
                }
              >
                <Row gutter={[0, { xs: 10, sm: 10 }]}>
                  <Col span={24}>
                    <SimpleDescription title="Output" paddingOff>
                      <BasicParagraph paddingOff>
                        {convertion.payload.outputName}
                      </BasicParagraph>
                    </SimpleDescription>
                  </Col>
                  <Col span={24}>
                    <Collapse>
                      <CollapsePanel key={convertion.id} header="Payload">
                        <BasicParagraph paddingOff>
                          {JSON.stringify(convertion.payload, null, 4)}
                        </BasicParagraph>
                      </CollapsePanel>
                    </Collapse>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
          dataSource={upcomingConvertions.map(([_, job]) => job)}
        />
      )}
      <BasicTitle level={4}>Fehlgeschlagene Jobs</BasicTitle>
      {failedConvertions.length === 0 ? (
        <>Keine</>
      ) : (
        <List
          style={{ width: "90%" }}
          renderItem={(job) => (
            <List.Item key={job.id}>
              <Card
                title={job.payload.convertName || job.payload.mergeNames}
                key={job.id}
                className={jobCard}
                extra={
                  <Button
                    disabled={mutationIsLoading}
                    onClick={() => killJob(job.id)}
                  >
                    Delete
                  </Button>
                }
              >
                <Row gutter={[0, { xs: 10, sm: 10 }]}>
                  <Col span={24}>
                    <SimpleDescription title="Output" paddingOff>
                      <BasicParagraph paddingOff>
                        {job.payload.outputName}
                      </BasicParagraph>
                    </SimpleDescription>
                  </Col>
                  <Col span={24}>
                    <SimpleDescription title="Error" paddingOff>
                      <BasicParagraph paddingOff>{job.error}</BasicParagraph>
                    </SimpleDescription>
                  </Col>
                  <Col span={24}>
                    <Collapse>
                      <CollapsePanel key={job.id} header="Payload">
                        <BasicParagraph paddingOff>
                          {JSON.stringify(job.payload, null, 4)}
                        </BasicParagraph>
                      </CollapsePanel>
                    </Collapse>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
          dataSource={failedConvertions.map(([_, job]) => job)}
        />
      )}
    </>
  );
};
