import { useLocation } from "react-router";
import { ApplicationErrorComponent } from "../../components/feedback/ApplicationErrorComponent";

export const ErrorPage: React.FC = () => {
  const { state } = useLocation();

  const error = (state as Error) || new Error("Fatal Null Error");

  return <ApplicationErrorComponent error={error} />;
};
