import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { UpdateMP3Request } from "../types/requestTypes";
import { APIURL } from "./apiUtils/apiUrl";

export const useUpdateMP3 = () => {
  const url = (id: string) => `${APIURL.mp3}/${id}`;

  return useMutation<AxiosResponse<void>, AxiosError, UpdateMP3Request>(
    ({ mp3Update, id }) => axios.patch<void>(url(id), mp3Update)
  );
};
