import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin, SpinProps } from "antd";
import { classes, style } from "typestyle";
import { CentralSpinner } from "./CentralSpinner";

interface LoadingWrapperProps extends SpinProps {
  isLoading?: boolean;
  highlightChildren?: boolean;
  message?: string;
}

const wrapperClass = style({
  backgroundColor: "#f0f2f590",
  fontSize: "1.25rem",
});

export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  children,
  isLoading = false,
  highlightChildren = false,
  message,
  ...spinProps
}) => {
  const { spinning = isLoading } = spinProps;
  return (
    <Spin
      {...spinProps}
      spinning={spinning}
      indicator={<Loading3QuartersOutlined spin />}
      size="large"
      tip={message}
      className={classes(!highlightChildren && wrapperClass)}
    >
      {children || (
        <CentralSpinner
          size="large"
          indicator={<Loading3QuartersOutlined spin />}
        />
      )}
    </Spin>
  );
};
