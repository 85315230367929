import {
  FFMPEGOutputFormat,
  ProcessedFileTargetDestination,
  ConvertType,
} from "../../../types/enums";
import { CreateConvertionPayload } from "../../../types/requestTypes";

export const InitialConverterPayload: CreateConvertionPayload = {
  convertName: "",
  mergeNames: [],
  height: 720,
  width: 1280,
  outputFormat: FFMPEGOutputFormat.MP4,
  outputName: "",
  copyCodec: false,
  processedFileTargetDestination: ProcessedFileTargetDestination.DONE,
  audioChannels: 1,
  fadeIn: true,
  fadeOut: true,
  fps: 25,
  videoBitrate: 1000,
  audioBitrate: 96,
  convertType: ConvertType.CONVERT,
};
