const mediaKeys = {
  all: "media" as const,

  lists: () => [mediaKeys.all, "list"] as const,
  mediaList: () => [...mediaKeys.lists(), "mediaList"] as const,
  mediaListQuery: (query: string) => [...mediaKeys.mediaList(), query] as const,
  distinctTagsList: () => [...mediaKeys.lists(), "distinctTagsList"] as const,

  details: () => [mediaKeys.all, "detail"] as const,
  detail: (fileId: string) => [...mediaKeys.details(), fileId] as const,
};

const observerKeys = {
  all: "observer" as const,

  status: () => [observerKeys.all, "status"] as const,
};

const converterKeys = {
  all: "converter" as const,

  withRefetch: () => [converterKeys.all, "refetch"] as const,
  list: () => [converterKeys.all, "list"] as const,
};

const generalInfosKeys = {
  all: "info" as const,

  markDown: () => [generalInfosKeys.all, "markDown"] as const,
};

const logKey = {
  all: "log" as const,
  lists: () => [logKey.all, "list"] as const,
  logListQuery: (query: string) => [...logKey.lists(), query] as const,
};

const databaseKey = {
  all: "db" as const,
  lists: () => [databaseKey.all, "list"] as const,
  dbTableInfo: () => [...databaseKey.lists(), "dbTableInfo"] as const,
};

const untrackedFileskey = {
  all: "untracked" as const,
  lists: () => [untrackedFileskey.all, "list"] as const,
  untrackedFiles: () =>
    [...untrackedFileskey.lists(), "untrackedFiles"] as const,
};

export const QUERYKEYS = {
  mediaKeys,
  observerKeys,
  generalInfosKeys,
  logKey,
  databaseKey,
  untrackedFileskey,
  converterKeys,
};
