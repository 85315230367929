import { Button, Form, message, Modal, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useUpdateUntrackedFile } from "../../../apiConnection/UntrackedFilesConnection";
import { BasicButton, BasicInput } from "../../../components";
import { UpdateUntrackedFileDto } from "../../../types/entities/dto/update.untrackedFile.dto";
import { UntrackedFile } from "../../../types/entities/untrackedFile.entity";
import { parseApiError } from "../../../utils/helperFuncs";

interface UntrackedFileEditModalProps {
  untrackedFile: UntrackedFile;
}
interface ModalState {
  visible: boolean;
  modalLoading: boolean;
}

export const UntrackedFileEditModal: React.FC<UntrackedFileEditModalProps> = ({
  untrackedFile,
}) => {
  const [modalState, setModalState] = useState<ModalState>({
    visible: false,
    modalLoading: false,
  });

  const [form] = useForm();

  const { mutate: updateUntrackedFile } = useUpdateUntrackedFile();

  const resetModalState = () => {
    form.resetFields();
    closeModal();
  };

  const saveUpdateUntrackedFile = (
    untrackedFileUpdate: UpdateUntrackedFileDto
  ) => {
    setModalState((state) => ({
      ...state,
      modalLoading: true,
    }));

    updateUntrackedFile(
      { id: untrackedFile.id, untrackedFileUpdate },
      {
        onSuccess: () => {
          message.success("Update erfolgreich. Liste wird aktualisiert.");
          closeModal();
        },
        onError: (error) => {
          message.error(parseApiError(error).statusText);
          resetModalState();
        },
      }
    );
  };

  const showModal = () => {
    setModalState({
      modalLoading: false,
      visible: true,
    });
  };

  const closeModal = () => {
    setModalState({
      modalLoading: false,
      visible: false,
    });
  };

  return (
    <>
      <Space>
        <BasicButton danger type="primary" onClick={showModal}>
          Edit
        </BasicButton>
      </Space>
      <Modal
        title={"Edit ID3 Tags"}
        visible={modalState.visible}
        confirmLoading={modalState.modalLoading}
        footer={[
          <Button
            key="cancel"
            onClick={resetModalState}
            disabled={modalState.modalLoading}
          >
            Cancel
          </Button>,
        ]}
      >
        <>
          <Form
            form={form}
            onFinish={saveUpdateUntrackedFile}
            initialValues={untrackedFile}
            layout="vertical"
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Von"
              name="artist"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Album"
              name="album"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Art"
              name="genre"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Track Nr"
              name="trackNr"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Jahr"
              name="year"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              disabled={modalState.modalLoading}
            >
              Speichern
            </Button>
          </Form>
        </>
      </Modal>
    </>
  );
};
