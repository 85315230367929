enum ENV {
  FRONTEND_KEYCLOAK_CLIENT_ID = "FRONTEND_KEYCLOAK_CLIENT_ID",
  KEYCLOAK_HOST_URL = "KEYCLOAK_HOST_URL",
  KEYCLOAK_REALM = "KEYCLOAK_REALM",
  API_URL = "API_URL",
  FRONTEND_PUBLIC_URL = "FRONTEND_PUBLIC_URL",
}

type Config = {
  API_URL: string;
  FRONTEND_PUBLIC_URL: string;
  FRONTEND_KEYCLOAK_CLIENT_ID: string;
  KEYCLOAK_HOST_URL: string;
  KEYCLOAK_REALM: string;
};

const devEnvs = {
  FRONTEND_KEYCLOAK_CLIENT_ID:
    process.env.REACT_APP_FRONTEND_KEYCLOAK_CLIENT_ID,
  KEYCLOAK_HOST_URL: process.env.REACT_APP_KEYCLOAK_HOST_URL,
  KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM,
  API_URL: process.env.REACT_APP_API_URL,
  FRONTEND_PUBLIC_URL: process.env.REACT_APP_FRONTEND_PUBLIC_URL,
};

const prodEnvs = {
  FRONTEND_KEYCLOAK_CLIENT_ID: (window as any)?._env_
    ?.REACT_APP_FRONTEND_KEYCLOAK_CLIENT_ID,
  KEYCLOAK_HOST_URL: (window as any)?._env_?.REACT_APP_KEYCLOAK_HOST_URL,
  KEYCLOAK_REALM: (window as any)?._env_?.REACT_APP_KEYCLOAK_REALM,
  API_URL: (window as any)?._env_?.REACT_APP_API_URL,
  FRONTEND_PUBLIC_URL: (window as any)?._env_?.REACT_APP_FRONTEND_PUBLIC_URL,
};

const getEnv = (variable: ENV) => {
  return process.env.NODE_ENV === "development"
    ? devEnvs[variable] || ""
    : prodEnvs[variable] || "";
};

export const Configuration: Config = {
  API_URL: getEnv(ENV.API_URL),
  FRONTEND_PUBLIC_URL: getEnv(ENV.FRONTEND_PUBLIC_URL),
  FRONTEND_KEYCLOAK_CLIENT_ID: getEnv(ENV.FRONTEND_KEYCLOAK_CLIENT_ID),
  KEYCLOAK_HOST_URL: getEnv(ENV.KEYCLOAK_HOST_URL),
  KEYCLOAK_REALM: getEnv(ENV.KEYCLOAK_REALM),
};
