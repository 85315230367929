import { AxiosError } from "axios";
import { MediaQueryArrays } from "../types/requestTypes";

export const getFileId = (fileName: string) => {
  return fileName.substring(0, fileName.lastIndexOf("."));
};

export const parseApiError = (
  error: AxiosError<unknown> | null
): { statusText: string; statusCode: number; message: string } => {
  if (!error?.response) {
    if (error?.message) {
      return {
        message: `Can not parse Api Error. Message: ${error.message}`,
        statusCode: 500,
        statusText: "",
      };
    } else {
      return {
        message: `Can not parse Api Error, no Message. ${JSON.stringify(
          error
        )}`,
        statusCode: 500,
        statusText: "",
      };
    }
  }

  const response = error.response as any;

  return {
    statusText: JSON.stringify(response.statusText || "Unknown error"),
    statusCode: response.status || undefined,
    message: response.data?.message || "",
  };
};

export const buildMediaListQuery = ({
  albums,
  artists,
  fuzzy,
  genres,
  years,
}: MediaQueryArrays): string => {
  const fuzzyQuery = fuzzy?.length ? `fuzzy=${fuzzy}` : undefined;
  const albumsQuery = albums?.length ? `&albums=${albums}` : undefined;
  const genresQuery = genres?.length ? `&genres=${genres}` : undefined;
  const artistsQuery = artists?.length ? `&artists=${artists}` : undefined;
  const yearsQuery = years?.length ? `&years=${years}` : undefined;
  return `${fuzzyQuery || ""}${albumsQuery || ""}${genresQuery || ""}${
    artistsQuery || ""
  }${yearsQuery || ""}`;
};
