import { Form, Col } from "antd";
import {
  useGetConverter,
  useCreateConvertJob,
} from "../../../../apiConnection/ConverterConnection";
import {
  CenterRow,
  LoadingWrapper,
  SuccessNotification,
} from "../../../../components";
import { ApiErrorComponent } from "../../../../components/feedback/ApiErrorComponent";
import { FFMPEGOutputFormat } from "../../../../types/enums";
import { CreateConvertionPayload } from "../../../../types/requestTypes";
import { InitialConverterPayload } from "../ConverterUtils";
import { AudioSettings } from "./AudioSettings";
import { GeneralSettings } from "./GeneralSettings";
import { VideoSettings } from "./VideoSettings";

export const ConverterForm = () => {
  const {
    data: converterData,
    isLoading,
    error: converterDataError,
    refetch: refetchConverter,
  } = useGetConverter(false);
  const firstFileInList = converterData?.fileNameList[0];

  const { mutate: createJob } = useCreateConvertJob();

  const handleSubmit = (payload: CreateConvertionPayload) => {
    createJob(
      { ...InitialConverterPayload, ...payload },
      {
        onSuccess: (data) => {
          SuccessNotification("Job created Successfully", [
            JSON.stringify(data),
          ]);
          refetchConverter();
        },
      }
    );
  };

  const [form] = Form.useForm<CreateConvertionPayload>();
  const currentOutputFormat = Form.useWatch("outputFormat", form);
  const copyCodec = Form.useWatch("copyCodec", form);

  const autoRenameOutput = () => {
    const fileName =
      (form.getFieldValue("convertName") as string) || firstFileInList;
    if (!fileName) return;

    const fileBaseName = fileName.split(".")[0];
    form.setFieldValue("outputName", `${fileBaseName}_NEW`);
    return fileBaseName;
  };

  if (isLoading || !converterData) {
    return <LoadingWrapper />;
  }

  if (converterDataError) {
    return <ApiErrorComponent apiError={converterDataError} />;
  }

  return (
    <CenterRow>
      <Col span={24}>
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            ...InitialConverterPayload,
          }}
          colon={false}
          layout="vertical"
        >
          <CenterRow gutter={50}>
            <Col span={16}>
              <GeneralSettings
                autoRenameOutput={autoRenameOutput}
                converterData={converterData}
                form={form}
              />
            </Col>
            {currentOutputFormat === FFMPEGOutputFormat.MP3 ? (
              <AudioSettings />
            ) : !copyCodec ? (
              <VideoSettings />
            ) : null}
          </CenterRow>
        </Form>
      </Col>
    </CenterRow>
  );
};
