import { Row, RowProps } from "antd";
import { style } from "typestyle";

interface CenterRowProps extends RowProps {
  trimmedWidth?: boolean;
  notCenterized?: boolean;
}

const centerContent = (trimmedWidth: boolean, notCenterized: boolean) =>
  style({
    width: trimmedWidth ? "80%" : "100%",
    justifyContent: notCenterized ? "normal" : "center",
  });

/**
 * A Row Component, which has default `justifyContent` to `center`.\
 * Width can be trimmed.
 * @param trimmedWidth If set, the width of the row is trimmed to 80% width of screen
 * @param centerized Default true
 */
export const CenterRow: React.FC<CenterRowProps> = ({
  children,
  trimmedWidth = false,
  notCenterized = false,
  ...rowProps
}) => {
  return (
    <Row {...rowProps} className={centerContent(trimmedWidth, notCenterized)}>
      {children}
    </Row>
  );
};
