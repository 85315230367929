import { Checkbox, Col, Form, InputNumber, Row } from "antd";
import { BasicTitle, CenterRow } from "../../../../components";
import { style } from "typestyle";

const inputField = style({ width: "80%" });
export const VideoSettings = () => {
  return (
    <Col span={8}>
      <CenterRow>
        <BasicTitle>MP4 Einstellungen</BasicTitle>
      </CenterRow>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name="fadeIn" valuePropName="checked">
            <Checkbox>Fade Video In</Checkbox>
          </Form.Item>
          <Form.Item name="fadeOut" valuePropName="checked">
            <Checkbox>Fade Video Out</Checkbox>
          </Form.Item>
          <Form.Item
            label="Output FPS"
            name="fps"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber className={inputField} />
          </Form.Item>
          <Form.Item
            label="Bitrate in kbit"
            name="videoBitrate"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber className={inputField} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Width"
            name="width"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber className={inputField} />
          </Form.Item>
          <Form.Item
            label="Height"
            name="height"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber className={inputField} />
          </Form.Item>
          <Form.Item
            label="Audio Channels"
            name="audioChannels"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber className={inputField} />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};
