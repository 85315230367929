import { Button, Card, CardProps, Col, Collapse, Row } from "antd";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { style } from "typestyle";
import {
  BasicParagraph,
  BasicTitle,
  CenterCol,
  ExistTag,
  SimpleDescription,
} from "..";
import { useAuth } from "../../providers/CustomKeycloakProvider";
import { MediaRoot } from "../../types/entities/media.entity";
import { Role } from "../../types/enums";

interface MediaItemCardProps extends CardProps {
  media?: MediaRoot;
  showSummaries?: boolean;
}

const mediaCard = style({
  width: "100%",
  boxShadow: "0px 0px 6px #D9D9D9",
  borderRadius: "10px",
});
export const MediaItemCard: React.FC<MediaItemCardProps> = ({
  media,
  showSummaries = false,
  ...cardProps
}) => {
  const { userHasRole } = useAuth();
  const mp3s = media?.mp3?.length ? media.mp3 : undefined;
  const mp4s = media?.mp4?.length ? media.mp4 : undefined;
  const pdfs = media?.pdf?.length ? media.pdf : undefined;
  const pptxs = media?.pptx?.length ? media.pptx : undefined;
  const markDowns = media?.markDown?.length ? media.markDown : undefined;

  const [activeSummary, setActiveSummary] = useState<
    string | string[] | undefined
  >(undefined);
  const [overwriteGlobalShowSummary, setOverwriteGlobalShowSummary] =
    useState(false);

  useEffect(() => {
    setOverwriteGlobalShowSummary(false);
  }, [setOverwriteGlobalShowSummary, showSummaries]);

  return (
    <>
      {!media ? (
        <Card {...cardProps} size="small" className={mediaCard} />
      ) : (
        <Card
          {...cardProps}
          className={mediaCard}
          title={
            <Row gutter={15} align="middle">
              <Col>
                <Button shape="round" type="primary">
                  <Link to={media.mediaId}>Öffnen</Link>
                </Button>
              </Col>
              <CenterCol>
                <BasicTitle
                  isSubTitle
                  style={{ whiteSpace: "normal", marginBottom: 0 }}
                >
                  {mp3s
                    ? mp3s
                        .sort((a, b) => (a.fileName < b.fileName ? -1 : 1))
                        .map((mp3) => (
                          <BasicParagraph key={mp3.id} paddingOff>
                            {mp3.title}
                          </BasicParagraph>
                        ))
                    : mp4s
                    ? mp4s.map((mp4) => (
                        <BasicParagraph key={mp4.id} paddingOff>
                          {mp4.fileName}
                        </BasicParagraph>
                      ))
                    : media.mediaId}
                </BasicTitle>
              </CenterCol>
            </Row>
          }
          size="small"
        >
          <Row gutter={[0, { xs: 10, sm: 10 }]}>
            <Col xs={12} lg={4}>
              <SimpleDescription title="Von" paddingOff>
                {mp3s?.map((mp3) => (
                  <BasicParagraph key={mp3.id} paddingOff>
                    {mp3.artist}
                  </BasicParagraph>
                )) || "Kein MP3-Tag."}
              </SimpleDescription>
            </Col>
            <Col xs={12} lg={5}>
              <SimpleDescription title="Art" paddingOff>
                {mp3s ? mp3s[0].genre : "Kein MP3-Tag."}
              </SimpleDescription>
            </Col>
            <Col xs={12} lg={6}>
              <SimpleDescription title="Veranstaltung" paddingOff>
                {mp3s ? mp3s[0].album : "Kein MP3-Tag."}
              </SimpleDescription>
            </Col>
            <Col xs={12} lg={4}>
              <SimpleDescription title="Jahr" paddingOff>
                {mp3s ? mp3s[0].year : "Kein MP3-Tag."}
              </SimpleDescription>
            </Col>
            {userHasRole(Role.PATCHMP3) && (
              <Col xs={12} lg={4}>
                <SimpleDescription title="Nummer" paddingOff>
                  {mp3s ? mp3s[0].trackNr : "Keine TrackNr"}
                </SimpleDescription>
              </Col>
            )}

            <Col xs={24} lg={5}>
              <SimpleDescription title="Vorhandene Dateien" paddingOff>
                <ExistTag
                  contentExists={!!mp3s}
                  fileType="Audio"
                  mediaId={media.mediaId}
                />

                <ExistTag
                  contentExists={!!mp4s}
                  fileType="Video"
                  mediaId={media.mediaId}
                />
                <ExistTag
                  contentExists={!!pdfs}
                  fileType="PDF"
                  mediaId={media.mediaId}
                />
                <ExistTag
                  contentExists={!!pptxs}
                  fileType="PowerPoint"
                  mediaId={media.mediaId}
                />
              </SimpleDescription>
            </Col>
            {markDowns && (
              <Col span={24}>
                <Collapse
                  bordered={false}
                  activeKey={
                    overwriteGlobalShowSummary
                      ? activeSummary
                      : showSummaries
                      ? markDowns.map((_, index) => String(index))
                      : undefined
                  }
                  onChange={(key) => {
                    setOverwriteGlobalShowSummary(true);
                    setActiveSummary(key);
                  }}
                >
                  {markDowns
                    .sort((a, b) => (a.fileName < b.fileName ? -1 : 1))
                    .map((markDown, index) => (
                      <Collapse.Panel
                        key={String(index)}
                        header={`Zusammenfassung - [${markDown.fileName}]`}
                      >
                        <ReactMarkdown
                          children={markDown.markDownContent}
                          remarkPlugins={[remarkGfm]}
                        />
                      </Collapse.Panel>
                    ))}
                </Collapse>
              </Col>
            )}
          </Row>
        </Card>
      )}
    </>
  );
};
