import { Card, Col, Divider, Form, List, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetLogs } from "../../../apiConnection/LogsConnection";
import {
  BasicParagraph,
  BasicTitle,
  CenterCol,
  CenterRow,
  LoadingWrapper,
  MediaItemCard,
  SimpleDescription,
} from "../../../components";
import { ApiErrorComponent } from "../../../components/feedback/ApiErrorComponent";
import { LogScope, SubScope } from "../../../types/enums";

interface ScopeForm {
  subscope: SubScope;
  logscope: LogScope;
}
export const Logs = () => {
  const [scopes, setScopes] = useState<ScopeForm>({
    subscope: SubScope.NONE,
    logscope: LogScope.FILEWORKER,
  });

  const {
    data: logsListResponse,
    isLoading: logListLoading,
    error: logListError,
    fetchNextPage,
  } = useGetLogs({ logSubScope: scopes.subscope, logScope: scopes.logscope });

  const logsFoundSum =
    useMemo(
      () => logsListResponse?.pages[0].maxLength,
      [logsListResponse?.pages]
    ) || 0;

  const logListData = useMemo(() => {
    if (!logsListResponse) return [];
    return logsListResponse.pages.flatMap((page) => page.logList);
  }, [logsListResponse]);

  const hasNextPage = useMemo(() => {
    return logListData.length < logsFoundSum;
  }, [logListData.length, logsFoundSum]);

  const [form] = useForm<ScopeForm>();
  const fireSearch = () => {
    setScopes(form.getFieldsValue());
  };

  if (logListLoading) {
    return <LoadingWrapper />;
  }

  if (logListError) {
    return <ApiErrorComponent apiError={logListError} />;
  }

  return (
    <CenterRow>
      <Col span={24}>
        <BasicParagraph>
          Active Version:{" "}
          {(window as any)?._env_?.REACT_APP_VERSION || "UNKNOWN"}
        </BasicParagraph>
      </Col>
      <Col span={24}>
        <Form
          onValuesChange={fireSearch}
          form={form}
          initialValues={{
            logscope: LogScope.FILEWORKER,
            subscope: SubScope.NONE,
          }}
          colon={false}
          layout="vertical"
        >
          <Form.Item name="logscope">
            <Select listHeight={500} size="large">
              {Object.entries(LogScope).map(([_, value]) => (
                <Select.Option key={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="subscope">
            <Select
              listHeight={500}
              size="large"
              disabled={scopes.logscope !== LogScope.TRACK}
            >
              {Object.entries(SubScope).map(([_, value]) => (
                <Select.Option key={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      <CenterCol span={24} centerize={false}>
        <Divider>
          {logListLoading
            ? "Lade Ergebnisse..."
            : `${logsFoundSum} Suchergebnis${logsFoundSum === 1 ? "" : "se"}`}
        </Divider>
        <CenterRow id="scrolldiv">
          <Col span={24}>
            {logListLoading && <MediaItemCard loading title="Suche Logs..." />}
            {!logListLoading && (
              <InfiniteScroll
                dataLength={logListData.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<MediaItemCard loading title="Lade mehr Dateien..." />}
                endMessage={<Divider plain>Ende der Suche.</Divider>}
                scrollThreshold={1}
              >
                <List
                  renderItem={(logItem, index) => (
                    <List.Item key={index}>
                      <>
                        <Card
                          style={{ width: "100%" }}
                          title={
                            <BasicTitle
                              leftAligned
                              isSubTitle
                              style={{ whiteSpace: "normal" }}
                            >
                              {new Date(logItem.created).toLocaleString(
                                "de-DE"
                              )}
                              {" | "}
                              {logItem.trackInfo
                                ? logItem.subScope
                                : logItem.message}
                            </BasicTitle>
                          }
                          size="small"
                        >
                          <Row gutter={[0, { xs: 10, sm: 10 }]}>
                            <Col xs={24} lg={6}>
                              <SimpleDescription
                                title={
                                  logItem.trackInfo ? "SubScope" : "LogScope"
                                }
                              >
                                {logItem.trackInfo
                                  ? logItem.subScope
                                  : logItem.logScope}
                              </SimpleDescription>
                            </Col>
                            {logItem.trackInfo && (
                              <>
                                <Col xs={24} lg={6}>
                                  <SimpleDescription title="Name">
                                    {logItem.trackInfo.name}
                                  </SimpleDescription>
                                </Col>
                                <Col xs={24} lg={6}>
                                  <SimpleDescription title="URL">
                                    {logItem.trackInfo.path}
                                  </SimpleDescription>
                                </Col>
                                {logItem.trackInfo?.query && (
                                  <Col xs={24} lg={6}>
                                    <SimpleDescription title="query">
                                      {Object.entries(
                                        logItem.trackInfo.query
                                      ).map(([key, value], index) => (
                                        <BasicParagraph key={index}>
                                          {key}:{JSON.stringify(value)}
                                        </BasicParagraph>
                                      ))}
                                    </SimpleDescription>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                        </Card>
                      </>
                    </List.Item>
                  )}
                  dataSource={logListData}
                />
              </InfiniteScroll>
            )}
          </Col>
        </CenterRow>

        {/* {data && (
          <>
            {!data.length && (
              <BasicTitle leftAligned level={5}>
                No Logs
              </BasicTitle>
            )}
            {data.map((d) => (
              <>
                <BasicTitle leftAligned level={5}>
                  {d.created}
                </BasicTitle>
                <Typography>{d.logScope}</Typography>
                <Typography>{d.message}</Typography>
                {d.trackInfo &&
                  Object.entries(d.trackInfo).map(([key, value]) => (
                    <Typography key={key}>
                      {key}:{JSON.stringify(value)}
                    </Typography>
                  ))}
                <Divider />
              </>
            ))}
          </>
        )} */}
        {/* {error && <Typography>{parseApiError(error).message}</Typography>} */}
      </CenterCol>
    </CenterRow>
  );
};
