import { Button, Col } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PageRoute } from "../../../utils/pageRoutes";

interface MenuItemProps {
  mobile?: {
    navigateAndCloseDrawer: (to: string) => void;
    pageRoute: PageRoute;
  };
  desktop?: {
    pageRoute: PageRoute;
  };
  actionItem?: {
    onClick: () => void;
    displayName: string;
  };
}

export const MenuItem: React.FC<MenuItemProps> = ({
  mobile,
  desktop,
  actionItem,
}) => {
  if (mobile) {
    return (
      <Col span={24}>
        <Button
          block
          onClick={() => mobile.navigateAndCloseDrawer(mobile.pageRoute.url)}
        >
          {mobile.pageRoute.displayName}
        </Button>
      </Col>
    );
  }

  if (desktop) {
    return (
      <Link to={desktop.pageRoute.url}>{desktop.pageRoute.displayName}</Link>
    );
  }

  if (actionItem) {
    return (
      <Button onClick={actionItem.onClick}>{actionItem.displayName}</Button>
    );
  }
  return null;
};
