import { Col } from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useGetInfos } from "../../../apiConnection/InfosConnection";
import { CenterRow, LoadingWrapper } from "../../../components";
import { ApiErrorComponent } from "../../../components/feedback/ApiErrorComponent";

export const Info = () => {
  const { isLoading, data: generalInfos, error } = useGetInfos();

  if (isLoading) {
    return <LoadingWrapper />;
  }

  if (error) {
    return <ApiErrorComponent apiError={error} />;
  }

  return (
    <>
      <LoadingWrapper isLoading={isLoading}>
        <CenterRow>
          <Col span={20}>
            <ReactMarkdown
              children={generalInfos || "# No Data"}
              remarkPlugins={[remarkGfm]}
            />
          </Col>
        </CenterRow>
      </LoadingWrapper>
    </>
  );
};
