import { Alert, Result, Space } from "antd";
import React from "react";

const ThrowErrorCompontent: React.FC<{ error: Error }> = ({ error }) => {
  throw error;
};

export const ApplicationErrorComponent: React.FC<{ error: Error }> = ({
  error,
}) => (
  <Space style={{ width: "100vw", justifyContent: "center" }}>
    <Space direction="vertical">
      <Result
        status="error"
        title="Unerwarteter Fehler"
        subTitle="Tut mir leid, da hat was nicht geklappt. Der Fehler wurde an Manuel weitergeleitet und wird bearbeitet."
      />
      <Alert.ErrorBoundary>
        <ThrowErrorCompontent error={error} />
      </Alert.ErrorBoundary>
    </Space>
  </Space>
);
