import { message, Modal, Space } from "antd";
import { useState } from "react";
import { useClearTable } from "../../../apiConnection/DatabaseConnection";
import { BasicButton, BasicParagraph } from "../../../components";
import { GetDatabaseTableInfoResponse } from "../../../types/responseTypes";
import { parseApiError } from "../../../utils/helperFuncs";

interface TableClearModalProps {
  dbKey: keyof GetDatabaseTableInfoResponse;
}
interface ModalState {
  visible: boolean;
  modalLoading: boolean;
}

export const TableClearModal: React.FC<TableClearModalProps> = ({ dbKey }) => {
  const [modalState, setModalState] = useState<ModalState>({
    visible: false,
    modalLoading: false,
  });
  const { mutate: tableClearMutation } = useClearTable();

  const resetModalState = () => {
    setModalState({
      visible: false,
      modalLoading: false,
    });
  };

  const clearTable = () => {
    setModalState((state) => ({
      ...state,
      modalLoading: true,
    }));

    tableClearMutation(
      { dbKey },
      {
        onSuccess: () => {
          message.success(`Table ${dbKey} cleared.`);
          setModalState({ visible: false, modalLoading: false });
        },
        onError: (error) => {
          message.error(parseApiError(error).statusText);
          setModalState((state) => ({ ...state, modalLoading: false }));
        },
      }
    );
  };

  const handleModalClose = () => {
    clearTable();
  };

  const showModal = () => {
    setModalState({
      modalLoading: false,
      visible: true,
    });
  };

  const handleCancel = () => {
    resetModalState();
  };

  return (
    <>
      <Space>
        <BasicButton onClick={() => showModal()} disabled={dbKey === "user"}>
          Clear {dbKey} Content
        </BasicButton>
      </Space>
      <Modal
        title={`Delete Table Content of ${dbKey}???`}
        open={modalState.visible}
        onOk={handleModalClose}
        confirmLoading={modalState.modalLoading}
        onCancel={handleCancel}
      >
        <BasicParagraph>
          All Data in {dbKey} will be erased. Sure?
        </BasicParagraph>
      </Modal>
    </>
  );
};
