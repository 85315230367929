import { PageRoutes } from "../../../utils/pageRoutes";

export const MenuEntries = [
  PageRoutes.medias,
  PageRoutes.logs,
  PageRoutes.database,
  PageRoutes.untracked,
  PageRoutes.info,
  PageRoutes.observer,
  PageRoutes.converter,
  PageRoutes.account,
] as const;
