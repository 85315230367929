import { Checkbox, Divider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { MediaListContent } from ".";
import { useGetMediaList } from "../../../apiConnection/MediaConnection";
import { useGetStreamKey } from "../../../apiConnection/StreamKeyConnection";
import { LoadingWrapper } from "../../../components";
import { ApiErrorComponent } from "../../../components/feedback/ApiErrorComponent";
import { MediaQueryArrays } from "../../../types/requestTypes";
import { MediaListForm } from "./MediaListForm";

export const MediaIndex = () => {
  useGetStreamKey();
  const [showSummaries, setShowSummaries] = useState(false);
  const { search: searchParamAsString } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const fuzzyParam = searchParams.getAll("fuzzy").map((f) => decodeURI(f));
  const albumsParam = searchParams.getAll("albums").map((f) => decodeURI(f));
  const artistsParam = searchParams.getAll("artists").map((f) => decodeURI(f));
  const genresParam = searchParams.getAll("genres").map((f) => decodeURI(f));
  const yearsParam = searchParams.getAll("years").map((year) => parseInt(year));
  const filesParam = searchParams.getAll("files").map((f) => decodeURI(f));
  const sortOrderParam =
    searchParams.get("sortOrder") === "ASC" ? "ASC" : "DESC";

  const initialParamValues: MediaQueryArrays = useMemo(
    () => ({
      fuzzy: fuzzyParam,
      artists: artistsParam,
      albums: albumsParam,
      genres: genresParam,
      years: yearsParam,
      files: filesParam,
      sortOrder: sortOrderParam,
    }),
    [
      albumsParam,
      artistsParam,
      filesParam,
      fuzzyParam,
      genresParam,
      sortOrderParam,
      yearsParam,
    ]
  );

  const {
    data: mediaListData,
    isLoading: mediaListLoading,
    error: mediaListError,
    fetchNextPage,
  } = useGetMediaList(searchParamAsString);

  const mp3sFoundSum =
    useMemo(() => mediaListData?.pages[0].maxLength, [mediaListData?.pages]) ||
    0;

  const mediaList = useMemo(() => {
    if (!mediaListData) return [];
    return mediaListData.pages.flatMap((page) => page.mediaList || []);
  }, [mediaListData]);

  const hasNextPage = useMemo(() => {
    return mediaList.length < mp3sFoundSum;
  }, [mp3sFoundSum, mediaList]);

  useEffect(() => {
    const scrolldiv = document.getElementById("scrolldiv");
    const { innerHeight } = window;
    if (!scrolldiv) return;
    if (
      mediaListData &&
      !mediaListLoading &&
      hasNextPage &&
      scrolldiv.clientHeight <= innerHeight
    ) {
      fetchNextPage();
    }
  }, [mediaListData, fetchNextPage, hasNextPage, mediaListLoading]);

  if (mediaListLoading) {
    return <LoadingWrapper />;
  }

  if (mediaListError) {
    return <ApiErrorComponent apiError={mediaListError} />;
  }

  return (
    <>
      <MediaListForm
        initialValues={initialParamValues}
        setSearchParams={setSearchParams}
      />
      <Divider plain style={{ marginBottom: "5px" }}>
        {mediaListLoading
          ? "Lade Ergebnisse..."
          : `${mp3sFoundSum} Suchergebnis${mp3sFoundSum === 1 ? "" : "se"}`}
      </Divider>
      <Checkbox
        checked={showSummaries}
        onChange={() => setShowSummaries(!showSummaries)}
      >
        Alle Zusammenfassungen aufklappen.
      </Checkbox>
      <MediaListContent
        mediaList={mediaList}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        loading={mediaListLoading}
        showSummaries={showSummaries}
      />
    </>
  );
};

export * from "./MediaListContent";
export * from "./MediaListForm";
