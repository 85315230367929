import { useKeycloak } from "@react-keycloak/web";
import { createContext, useContext } from "react";
import { APIURL } from "../apiConnection/apiUtils/apiUrl";
import { useDeleteStreamkey } from "../apiConnection/StreamKeyConnection";
import { CentralSpinner } from "../components";
import { Role } from "../types/enums";
import { ActiveUser } from "../types/responseTypes";

interface AuthContextState {
  activeUser: ActiveUser | null;
  login: () => void;
  logout: () => void;
  initialized: boolean;
  getAuthHeader: () => { Authorization: string };
  /**
   * hasRole checks if user has the given role(s).
   * matchAll is default false, and means that only one role must be present by default.
   * set matchAll to true, if all given roles need to be present.
   */
  userHasRole: (roles?: Role[] | Role, matchAll?: boolean) => boolean;
  accountManagement: () => void;
}
const AuthContext = createContext<AuthContextState>({
  activeUser: null,
  login: () => null,
  logout: () => null,
  initialized: false,
  getAuthHeader: () => ({ Authorization: "" }),
  userHasRole() {
    return false;
  },
  accountManagement: () => null,
});

export const useAuth = () => useContext(AuthContext);

export const CustomKeycloakProvider: React.FC = ({ children }) => {
  const { initialized, keycloak } = useKeycloak();
  const deleteStreamKey = useDeleteStreamkey();

  const roles: Role[] = [];
  let email = "";
  let name = "";

  if (keycloak.authenticated) {
    // console.log(JSON.stringify(keycloak.tokenParsed, null, 4));

    const mlibClientRoles: string[] | undefined =
      keycloak.tokenParsed?.frontendRoles;

    mlibClientRoles?.forEach((role) => {
      if (Object.values(Role).includes(role as Role)) {
        const uRole = role as Role;
        !roles.includes(uRole) && roles.push(uRole);
      }
    });
    email = keycloak.tokenParsed?.preferred_username || "";
    name = keycloak.tokenParsed?.name || "";
    // console.log(keycloak.tokenParsed);
  }

  const activeUser: ActiveUser | null = keycloak.authenticated
    ? { email, roles, name }
    : null;

  const logout = async () => {
    try {
      await deleteStreamKey(getAuthHeader());
    } finally {
      await keycloak.logout({ redirectUri: APIURL.frontendUrl });
    }
  };
  const login = () => keycloak.login();

  const hasRole = (roles?: Role[] | Role, matchAll = false): boolean => {
    if (!activeUser?.roles) return false;
    if (!roles) return true;
    if (!Array.isArray(roles)) return activeUser.roles.includes(roles);
    if (!roles.length) return true;

    let result = false;
    for (const role of roles) {
      result = activeUser.roles.includes(role);
      if (matchAll !== result) break;
    }
    return result;
  };

  const getAuthHeader = () => {
    return { Authorization: `Bearer ${keycloak.token}` };
  };

  console.log("Active User: ", { email, roles });
  return (
    <AuthContext.Provider
      value={{
        initialized,
        activeUser,
        login,
        logout,
        getAuthHeader,
        userHasRole: hasRole,
        accountManagement: keycloak.accountManagement,
      }}
    >
      {!initialized ? <CentralSpinner tip="Initializing Website" /> : children}
    </AuthContext.Provider>
  );
};
