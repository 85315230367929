import { useParams } from "react-router";
import { PathParam } from "../../../../types/clientEnums";
import { NotFound } from "../../../public";
import { MediaDetails } from "./MediaDetails";

export const MediaDetailIndex = () => {
  const { mediaId } = useParams<PathParam.MEDIAID>();
  if (!mediaId) return <NotFound />;
  return <MediaDetails mediaId={mediaId} />;
};

export * from "./MediaDetails";
