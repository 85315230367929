import { Button, Divider, Form, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { MP3 } from "../../types/entities/mp3.entity";
import { parseApiError } from "../../utils/helperFuncs";
import { BasicButton } from "../inputs/BasicButton";
import { BasicParagraph } from "../text/BasicParagraph";
import { useCreateTranscription } from "../../apiConnection/TranscripConnection";

interface CreateTranscriptModalProps {
  mp3: MP3;
}

interface ModalState {
  visible: boolean;
  modalLoading: boolean;
}

export const CreateTranscriptModal: React.FC<CreateTranscriptModalProps> = ({
  mp3,
}) => {
  const [modalState, setModalState] = useState<ModalState>({
    visible: false,
    modalLoading: false,
  });

  const [form] = useForm();

  const { mutate: createTranscription } = useCreateTranscription();

  const resetModalState = () => {
    form.resetFields();
    closeModal();
  };

  const handleCreateTranscription = () => {
    setModalState((state) => ({
      ...state,
      modalLoading: true,
    }));

    createTranscription(
      { transcribe: { fileId: mp3.id } },
      {
        onSuccess: () => {
          message.success("Transcription gestartet.");
          closeModal();
        },
        onError: (error) => {
          message.error(parseApiError(error).statusText);
          resetModalState();
        },
      }
    );
  };

  const showModal = () => {
    setModalState({
      modalLoading: false,
      visible: true,
    });
  };

  const closeModal = () => {
    setModalState({
      modalLoading: false,
      visible: false,
    });
  };

  return (
    <>
      <BasicButton type="primary" onClick={showModal}>
        Transcription
      </BasicButton>
      <Modal
        title={"Transcription erstellen"}
        open={modalState.visible}
        confirmLoading={modalState.modalLoading}
        footer={[
          <Button
            key="cancel"
            onClick={resetModalState}
            disabled={modalState.modalLoading}
          >
            Cancel
          </Button>,
        ]}
        onCancel={resetModalState}
      >
        <BasicParagraph>
          Es wird eine AI Generierte Transkription gestartet.
        </BasicParagraph>
        {/* <BasicParagraph>
          Die Dauer der MP3 ist 0000 sekunden und wird ca 99$ kosten.
        </BasicParagraph> */}
        <Divider />
        <Form
          form={form}
          onFinish={handleCreateTranscription}
          initialValues={{ summarize: false }}
          layout="horizontal"
          colon={false}
        >
          {/* <Form.Item
            label="Mit Zusammenfassung"
            name="summarize"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item> */}

          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            disabled={modalState.modalLoading}
          >
            Transkripieren
          </Button>
        </Form>
        {/* <>
          <Form
            form={form}
            onFinish={createTranscription}
            initialValues={mp3}
            layout="vertical"
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Von"
              name="artist"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Album"
              name="album"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Art"
              name="genre"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Track Nr"
              name="trackNr"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Form.Item
              label="Jahr"
              name="year"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <BasicInput />
            </Form.Item>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              disabled={modalState.modalLoading}
            >
              Speichern
            </Button>
          </Form>
        </> */}
      </Modal>
    </>
  );
};
