import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { GetDistinctMp3TagsResponse } from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetFiltertags = () => {
  const url = APIURL.filtertags;
  const queryKey = QUERYKEYS.mediaKeys.distinctTagsList();

  return useQuery<GetDistinctMp3TagsResponse, AxiosError>(queryKey, () =>
    axios.get<GetDistinctMp3TagsResponse>(url).then((r) => r.data)
  );
};
