export enum PathParam {
  HOME = "home",
  OBSERVER = "observer",
  MEDIAS = "medias",
  MEDIAID = "mediaId",
  LOGS = "logs",
  DATABASE = "database",
  UNTRACKED = "untracked",
  INFO = "info",
  ACCOUNT = "account",
  CONVERTER = "converter",
}
