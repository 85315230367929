import { Typography } from "antd";
import { useGetUntrackedFiles } from "../../../apiConnection/UntrackedFilesConnection";
import {
  BasicParagraph,
  EditableMP3DetailCard,
  LoadingWrapper,
} from "../../../components";
import { ApiErrorComponent } from "../../../components/feedback/ApiErrorComponent";
import { parseApiError } from "../../../utils/helperFuncs";

export const UntrackedFiles = () => {
  const {
    isLoading,
    data: untrackedFiles,
    error: apiError,
  } = useGetUntrackedFiles();

  if (isLoading) {
    return <LoadingWrapper />;
  }

  if (apiError) {
    return <ApiErrorComponent apiError={apiError} />;
  }
  return (
    <>
      {!untrackedFiles || untrackedFiles?.length === 0 ? (
        <BasicParagraph>Keine Untracked Files </BasicParagraph>
      ) : (
        untrackedFiles.map((untrackedFile) => (
          <EditableMP3DetailCard key={untrackedFile.id} mp3={untrackedFile} />
        ))
      )}
      {apiError && (
        <Typography>{parseApiError(apiError).statusText}</Typography>
      )}
    </>
  );
};
