import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { UpdateUntrackedFileRequest } from "../types/requestTypes";
import { GetUntrackedFilesStatusResponse } from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetUntrackedFiles = () => {
  const url = APIURL.untrackedFile;
  const queryKey = QUERYKEYS.untrackedFileskey.untrackedFiles();

  return useQuery<GetUntrackedFilesStatusResponse, AxiosError>(queryKey, () =>
    axios.get<GetUntrackedFilesStatusResponse>(url).then((r) => r.data)
  );
};

export const useUpdateUntrackedFile = () => {
  const url = (id: string) => `${APIURL.untrackedFile}/${id}`;

  return useMutation<
    AxiosResponse<void>,
    AxiosError,
    UpdateUntrackedFileRequest
  >(({ untrackedFileUpdate, id }) =>
    axios.patch<void>(url(id), untrackedFileUpdate)
  );
};
