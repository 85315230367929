import { DownloadOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { style } from "typestyle";
import { APIURL } from "../../apiConnection/apiUtils/apiUrl";

interface MediaDownloadButtonProps {
  fileName?: string;
  fileType: "Audio" | "Video" | "PDF" | "PPTX" | "TXT";
}

const downloadButton = style({
  width: "90%",
  maxWidth: "400px",
  margin: "0.5rem 0 0 0",
});

export const MediaDownloadButton: React.FC<MediaDownloadButtonProps> = ({
  fileName,
  fileType,
}) => {
  if (!fileName) {
    return (
      <>
        <Button
          type="primary"
          shape="round"
          icon={<DownloadOutlined />}
          className={downloadButton}
          disabled={true}
        >
          {`Kein ${fileType} Vorhanden`}
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<DownloadOutlined />}
        className={downloadButton}
        href={APIURL.download(fileName)}
      >
        {fileType}
      </Button>
      <Typography.Text type="secondary">{fileName}</Typography.Text>
    </>
  );
};
