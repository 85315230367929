import { Divider } from "antd";
import { AudioBlock, BasicTitle, CenterRow } from "../../../../components";
import { MP3 } from "../../../../types/entities/mp3.entity";

interface MP3sProps {
  mp3s: Array<MP3> | undefined;
}
export const MP3s: React.FC<MP3sProps> = ({ mp3s }) => {
  return (
    <CenterRow gutter={[0, 12]}>
      {!mp3s ? (
        <>
          <BasicTitle level={5}>Keine MP3s vorhanden.</BasicTitle>
          <Divider />
        </>
      ) : (
        mp3s
          .sort((a, b) => (a.fileName < b.fileName ? -1 : 1))
          .map((mp3) => <AudioBlock key={mp3.id} mp3={mp3} />)
      )}
    </CenterRow>
  );
};
