import { Typography } from "antd";
import { ParagraphProps } from "antd/lib/typography/Paragraph";

interface BasicParagraphProps extends ParagraphProps {
  paddingOff?: boolean;
}

export const BasicParagraph: React.FC<BasicParagraphProps> = ({
  children,
  paddingOff = false,
  ...paragraphProps
}) => {
  return (
    <Typography.Paragraph
      {...paragraphProps}
      style={{ margin: paddingOff ? 0 : undefined, whiteSpace: "pre-wrap" }}
    >
      {children}
    </Typography.Paragraph>
  );
};
