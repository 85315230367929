import { ReactKeycloakProvider } from "@react-keycloak/web";
import "antd/dist/antd.min.css";
import "array-flat-polyfill";
import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { AxiosConfigProvider } from "./providers/AxiosProvider";
import { CustomErrorBoundary } from "./providers/CustomErrorBoundary";
import { CustomKeycloakProvider } from "./providers/CustomKeycloakProvider";
import { RouteProvider } from "./providers/RouteProvider";
import "./styleOverride.css";
import { Configuration } from "./utils/configuration";
import { ScrollToTop } from "./utils/ScrollToTop";

const keycloakInstance = new Keycloak({
  clientId: Configuration.FRONTEND_KEYCLOAK_CLIENT_ID,
  realm: Configuration.KEYCLOAK_REALM,
  url: Configuration.KEYCLOAK_HOST_URL,
});

const keycloakConfig: KeycloakInitOptions = {
  checkLoginIframe: false,
  // enableLogging: true,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 1000 * 60, retry: 0 },
    mutations: { retry: 0 },
  },
});

ReactDOM.render(
  <CustomErrorBoundary level="application">
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={keycloakConfig}
    >
      <React.StrictMode>
        <BrowserRouter>
          <CustomKeycloakProvider>
            <AxiosConfigProvider>
              <CustomErrorBoundary level="router">
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <ScrollToTop />
                  <RouteProvider />
                </QueryClientProvider>
              </CustomErrorBoundary>
            </AxiosConfigProvider>
          </CustomKeycloakProvider>
        </BrowserRouter>
      </React.StrictMode>
    </ReactKeycloakProvider>
  </CustomErrorBoundary>,
  document.getElementById("root")
);
