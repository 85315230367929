import {
  Form,
  Select,
  Space,
  Button,
  FormInstance,
  Col,
  Divider,
  Row,
} from "antd";
import { FC } from "react";
import { APIURL } from "../../../../apiConnection/apiUtils/apiUrl";
import {
  BasicTitle,
  BasicInput,
  CenterRow,
  MediaPlayer,
} from "../../../../components";
import {
  FFMPEGOutputFormat,
  ProcessedFileTargetDestination,
} from "../../../../types/enums";
import { GetConverterResponse } from "../../../../types/responseTypes";

interface GeneralSettingsProps {
  autoRenameOutput: () => void;
  converterData: GetConverterResponse;
  form: FormInstance;
}
export const GeneralSettings: FC<GeneralSettingsProps> = ({
  autoRenameOutput,
  converterData,
  form,
}) => {
  const currentFileName = Form.useWatch("convertName", form) || undefined;
  return (
    <>
      <BasicTitle>Allgemein</BasicTitle>
      <Form.Item label="Datei" name="convertName" rules={[{ required: true }]}>
        <Select
          placeholder="Klicken oder eingeben."
          onChange={autoRenameOutput}
        >
          {converterData.fileNameList.map((file) => (
            <Select.Option key={file}>{file}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {currentFileName?.endsWith(".mp4") && (
        <CenterRow>
          <Col xs={24} sm={12}>
            <MediaPlayer
              type="video"
              mediaUrl={APIURL.converterStream(currentFileName)}
            />
          </Col>
        </CenterRow>
      )}
      {currentFileName?.endsWith(".mp3") && (
        <CenterRow>
          <Col span={24}>
            <MediaPlayer
              type="audio"
              mediaUrl={APIURL.converterStream(currentFileName)}
            />
          </Col>
        </CenterRow>
      )}
      <Divider />
      <Form.Item
        label="Output Dateiname (ohne extension)"
        name="outputName"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <BasicInput />
      </Form.Item>
      <Space>
        <Form.Item
          label="Anfang scheiden"
          name="trimStart"
          rules={[
            {
              pattern: /^\d{2}:\d{2}:\d{2}$/,
            },
          ]}
        >
          <BasicInput />
        </Form.Item>
        <Form.Item
          label="Ende scheiden"
          name="trimEnd"
          rules={[
            {
              pattern: /^\d{2}:\d{2}:\d{2}$/,
            },
          ]}
        >
          <BasicInput />
        </Form.Item>
        {/* <Form.Item noStyle name="copyCodec" valuePropName="checked">
          <Checkbox>Nur schneiden, ohne zu kodieren.</Checkbox>
        </Form.Item> */}
      </Space>
      <Row gutter={50}>
        <Col span={10}>
          <Form.Item
            label="Output"
            name="outputFormat"
            rules={[{ required: true }]}
          >
            <Select>
              {Object.values(FFMPEGOutputFormat).map((value) => (
                <Select.Option key={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label="Fertige Dateien verschieben nach Ordner"
            name="processedFileTargetDestination"
          >
            <Select>
              {Object.values(ProcessedFileTargetDestination).map((value) => (
                <Select.Option key={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space size="large">
          <Button type="primary" shape="round" htmlType="submit">
            Konvertieren
          </Button>
        </Space>
      </Form.Item>
    </>
  );
};
