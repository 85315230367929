import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { GetGeneralInfosResponse } from "../types/responseTypes";
import { APIURL } from "./apiUtils/apiUrl";
import { QUERYKEYS } from "./apiUtils/queryKeys";

export const useGetInfos = () => {
  const url = APIURL.generalInfos;
  const queryKey = QUERYKEYS.generalInfosKeys.markDown();

  return useQuery<GetGeneralInfosResponse, AxiosError>(queryKey, () =>
    axios.get<GetGeneralInfosResponse>(url).then((r) => r.data)
  );
};
