import { Button, ButtonProps } from "antd";
import { style } from "typestyle";

interface BasicButtonProps extends ButtonProps {
  fullWidth?: boolean;
}

const buttonStyle = (fullWidth: boolean) =>
  style({
    width: fullWidth ? "100%" : undefined,
  });

export const BasicButton: React.FC<BasicButtonProps> = ({
  children,
  fullWidth = false,
  ...buttonProps
}) => {
  return (
    <Button {...buttonProps} className={buttonStyle(fullWidth)}>
      {children}
    </Button>
  );
};
