import { Divider } from "antd";
import { BasicTitle, CenterRow, VideoBlock } from "../../../../components";
import { MP4 } from "../../../../types/entities/mp4.entity";

interface MP4sProps {
  mp4s: Array<MP4> | undefined;
}
export const MP4s: React.FC<MP4sProps> = ({ mp4s }) => {
  return (
    <CenterRow>
      {!mp4s ? (
        <>
          <BasicTitle level={5}>Keine Videos vorhanden.</BasicTitle>
          <Divider />
        </>
      ) : (
        <>
          {mp4s
            .sort((a, b) => (a.fileName < b.fileName ? -1 : 1))
            .map((mp4) => (
              <VideoBlock key={mp4.id} mp4={mp4} />
            ))}
        </>
      )}
    </CenterRow>
  );
};
