import Title, { TitleProps } from "antd/lib/typography/Title";
import { classes, style } from "typestyle";

interface BasicTitleProps extends TitleProps {
  leftAligned?: boolean;
  isSubTitle?: boolean;
}

const centerized = style({
  textAlign: "center",
});
const flowWrap = style({
  overflowWrap: "anywhere",
});

/**
 * BasicTitle can set a level for the size.\
 * Default level is set to 2 (1-5 possible)
 * @param leftAligned sets the title textAlign to left. Default is center.
 * @param isSubTitle sets the title level to 5. Just a shortcut for `level={5}`. Dont use level and subTitle together.
 */
export const BasicTitle: React.FC<BasicTitleProps> = ({
  children,
  leftAligned = false,
  isSubTitle = false,
  ...titleProps
}) => {
  const { level = isSubTitle ? 5 : 2 } = titleProps;

  return (
    <Title
      {...titleProps}
      level={level}
      className={classes(!leftAligned && centerized, flowWrap)}
    >
      {children}
    </Title>
  );
};
