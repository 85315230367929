import { Outlet } from "react-router";
import { CentralSpinner } from "../../components";
import { useAuth } from "../../providers/CustomKeycloakProvider";
import { Role } from "../../types/enums";
import { NotAuthorized } from "../public/NotAuthorized";

interface ProtectorProps {
  accessRoles?: Role | Role[];
  routeOutlet?: boolean;
}

export const Protector: React.FC<ProtectorProps> = ({
  accessRoles,
  routeOutlet = false,
  children,
}) => {
  const { initialized, activeUser, login, userHasRole } = useAuth();

  if (!initialized) {
    return <CentralSpinner />;
  }

  if (!activeUser && accessRoles) {
    login();
    return <CentralSpinner />;
  }

  if (!accessRoles) {
    return routeOutlet ? <Outlet /> : <>{children}</>;
  }

  if (userHasRole(accessRoles)) {
    return routeOutlet ? <Outlet /> : <>{children}</>;
  }

  return <NotAuthorized />;
};
